<template>
  <button class="btn m-0 p-0" @click="openMaterial">
    <div :class="`copybook-card ${deny_access ? 'lock' : ''}`">
      <i v-if="deny_access" class="icon-lock"></i>
      <div class="btn-clip">
        <div class="dot"></div>
      </div>
      <div class="materials-cover-wrapper">
        <div class="materials-cover" :style="styles"></div>
      </div>
      <div class="materials-paper"></div>
    </div>
  </button>
</template>

<script>
import SalesMixin from "@/functions/mixins/sales";
import get from "lodash/get";
export default {
  mixins: [SalesMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openMaterial() {
      if (this.deny_access) {
        this.$message.warning(
          "Você ainda não possui a assinatura deste material, nem da formação que ele pertence."
        );
        return;
      }

      this.$router.push({ name: "material", params: { id: this.item.id } });
    },
  },
  computed: {
    deny_access() {
      const is_material_purchased = this.check_material_purchase(this.item.id);

      const formation_id = get(this.item, "formation.id", null);

      if (formation_id) {
        return (
          !is_material_purchased && !this.check_formation_purchase(formation_id)
        );
      }

      return !is_material_purchased;
    },
    styles() {
      const lock_gradient = this.deny_access
        ? "linear-gradient(90deg, rgba(0,0,0, 0.5) 100%, rgba(0,0,0, 0.5) 100%),"
        : "";

      return `background-image:${lock_gradient}url(${this.item.thumb || ""});`;
    },
  },
};
</script>
<style lang="scss" scoped>
.copybook-card {
  position: relative;
  aspect-ratio: 170/269;
  width: 170px;
  height: 269px;

  @media screen and (max-width: 768px) {
    aspect-ratio: 140/229;
    width: 140px;
    height: 229px;
  }

  @media screen and (max-width: 320px) {
    aspect-ratio: 120/209;
    width: 120px;
    height: 209px;
  }

  background-color: #c59778;
  background-size: cover;

  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 12px 12px 12px 1px;

  &.lock {
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 28px;
      color: #fff;
      z-index: 999;
    }
  }

  .btn-clip {
    position: absolute;
    top: 44%;
    right: -5%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding-left: 5px;

    width: 44.37px;
    height: 22.15px;
    z-index: 4;
    background: #fdcbaa;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 30px 30px;
    .dot {
      border-radius: 50%;
      height: 11.08px;
      width: 11.09px;
      background: #cd9d7d;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .materials-paper {
    background: #ece4df;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
  }

  .materials-cover-wrapper {
    position: absolute;
    top: 0;
    bottom: 2px;
    left: 0;
    right: 4px;
    border-radius: 10px;

    background: #eaa475;
    padding: 3px;
    clip-path: polygon(100% 0, 100% 25%, 93% 30%, 93% 100%, 0 100%, 0 0);
  }

  .materials-cover {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border-radius: 8px;

    background-color: #000;
    background-size: cover;

    clip-path: polygon(100% 0, 100% 24%, 92% 29%, 92% 100%, 0 100%, 0 0);
  }
}
</style>
