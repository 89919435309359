import { adapt_first_sessions } from "../../functions/helpers";

export default {
  get(context, query = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(query);
      window.axios
        .get(`/forum/posts?${query_params}`)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setAllTopics", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  get_post(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/${id}/posts`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  get_replies(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`forum/${id}/parent`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  comment_create(context, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/forum/create`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  post_create(context, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/forum/posts/create`, { post_comment: [], ...payload })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  interact_comment(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/forum/${id}/interactions`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  interact_post(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/forum/post/${id}/interactions`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  session(context, query = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(query);
      window.axios
        .get(`/forum/posts/sessions?${query_params}`)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setSessions", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  course(context, query = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(query);
      window.axios
        .get(`/forum/posts/course?` + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setCourse", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  lesson(context, query = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(query);
      window.axios
        .get(`/forum/posts/lessons?` + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setLessons", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  material(context, query = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(query);
      window.axios
        .get(`/forum/posts/material?` + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setMaterials", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  live(context, query = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(query).toString();
      window.axios
        .get(`/forum/posts/lives?` + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setLives", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },

  user_profile(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/accounts/${id}/profile`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  get_user_sessions(context, { id, query, l }) {
    let queryParams = 0;
    if (query) {
      queryParams = `order=desc&query=${query}`;
    } else {
      queryParams = `limit=${l || 10}&order=desc`;
    }
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/strength/${id}/user?${queryParams}`)
        .then(({ data: { count, next, previous, results } }) => {
          resolve({
            count,
            next,
            previous,
            results: adapt_first_sessions(results),
          });
        })
        .catch(reject);
    });
  },
};
