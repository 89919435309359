<template>
  <div
    v-if="!loading"
    class="template-default d-flex flex-column align-items-end"
  >
    <transition name="fade">
      <div
        class="blocking-bg custom-d-lg-none"
        v-if="showMenu"
        @click="closeMenu"
      />
    </transition>

    <side-menu
      :showMenu="showMenu"
      :lockedMenu="lockedMenu"
      @set-visibility="setMenuVisibility"
      @close="closeMenu"
      @open="openMenu"
    />

    <header>
      <button class="btn btn-menu custom-d-lg-none" @click="showMenu = true">
        <i class="icon-menu"></i>
      </button>
      <div class="brand">
        <router-link class="option text-dark" :to="{ name: 'home' }">
          MDF
        </router-link>
      </div>
      <back-route-button class="btn-back-lesson" />
    </header>

    <main
      class="flex-fill d-flex flex-column"
      :class="{ showMenu: showMenu || lockedMenu }"
    >
      <div class="slot-wrapper flex-fill position-relative">
        <bread-crumbs />

        <slot />

        <session-modal
          v-if="my_active_session && !open_finish_session"
          v-model="show_my_session"
          @open-finish-1="open_finish_session = true"
        />

        <finish-session-modal v-model="open_finish_session" />
      </div>
      <app-footer @logout="logoutDialog = true" />
    </main>

    <confirmation-modal
      text="Deseja mesmo sair?"
      v-model="logoutDialog"
      @confirm="logout"
      title="Sair"
    />

    <!-- <message-modal 
    title="Ei, mulher!"
    :text="messageContent"
    v-model="showMessage"
    /> -->
  </div>
</template>

<script>
import FinishSessionModal from "@/components/strength-session/finish/FinishSessionModal.vue";
import SessionModal from "@/components/strength-session/SessionModal.vue";
import BackRouteButton from "@/components/BackRouteButton";
import AppFooter from "@/components/templates/AppFooter";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import SideMenu from "@/components/SideMenu.vue";
import { mapState } from "vuex";
// import MessageModal from "../components/MessageModal.vue";

export default {
  components: {
    FinishSessionModal,
    BackRouteButton,
    SessionModal,
    BreadCrumbs,
    AppFooter,
    SideMenu,
    // MessageModal
  },
  data() {
    return {
      showMessage: false,
      showMenu: false,
      logoutDialog: false,
      menuButtonTimeout: null,
      open_finish_session: false,
      lockedMenu: window.localStorage.getItem("Mdf_UnLockedMenu"),
      messageContent:
        'Agora você também pode entrar em contato com o nosso suporte através do número de <a class="fw-bold text-decoration-underline" href="https://api.whatsapp.com/send?phone=5534992513000&text=Ol%C3%A1!%20Preciso%20de%20suporte%20para%20a%20plataforma%20-%20Mulheres%20de%20for%C3%A7a" target="_blank">WhatsApp</a>. <br><br> O horário de atendimento é o comercial, como de costume. Espero que este recurso possa te ajudar! <br><br> Bons estudos!!!',
    };
  },
  computed: {
    ...mapState("sessions", ["show_my_session", "my_active_session"]),
    ...mapState("user", ["messages", "user"]),
    ...mapState(["loading"]),
  },
  created() {
    this.$store.dispatch("user/get");
  },
  mounted() {
    setTimeout(() => {
      this.showMessage = true;
    }, 1000);
  },
  methods: {
    backToModalSession() {
      this.open_finish_step_2 = false;
      this.open_finish_step_1 = true;
    },
    setMenuVisibility(visibility) {
      if (this.menuButtonTimeout) {
        clearTimeout(this.menuButtonTimeout);
      }
      this.menuButtonTimeout = setTimeout(() => {
        this.showMenu = visibility;
      }, 150);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    clickLockMenu() {
      this.lockedMenu = !this.lockedMenu;
      if (this.lockedMenu) {
        window.localStorage.removeItem("mdf_unlockedMenu");
      } else {
        this.showMenu = false;
        window.localStorage.setItem("mdf_unlockedMenu", 1);
      }
    },
    openMenu() {
      this.setMenuVisibility(true);
    },
    closeMenu() {
      this.setMenuVisibility(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
$sidebar__opened__width: 260px;
$sidebar__closed__width: 60px;

.template-default {
  min-height: 100vh;
  position: relative;
  display: flex;

  @media screen and (max-width: 1050px) {
    padding-top: 56px;
  }

  .custom-d-lg-none {
    @media screen and (min-width: 1051px) {
      display: none;
    }
  }

  .blocking-bg {
    position: fixed;
    z-index: 3;
    background: rgba(0, 10, 20, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  main {
    position: relative;
    width: calc(100% - $sidebar__closed__width);

    @media screen and (max-width: 1050px) {
      width: 100%;
    }
    &.showMenu {
      width: calc(100% - $sidebar__closed__width);
      @media screen and (max-width: 1050px) {
        width: 100%;
      }
    }
  }

  header {
    min-height: 76px;
    @media screen and (max-width: 1050px) {
      min-height: 55px;
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    flex: 1 1 auto;

    .btn-menu {
      box-shadow: none;
      color: #0d0601;
      font-size: 18px;
      position: absolute;
      left: 0%;
      top: 50%;
      transform: translateX(0%) translateY(-50%);
    }

    .brand {
      @media screen and (max-width: 1050px) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-weight: bold;
      }
    }

    .btn-back-lesson {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
    }
  }

  .slot-wrapper {
    transition: 0.3s padding ease;
    background: #f0eeff;
  }
}
</style>
