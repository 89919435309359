<template>
  <div class="components-home-live-list">
    <div v-if="exclusiveLives && exclusiveLives.length" class="exclusive my-3">
      <h5 class="mb-4">Assista agora a Live Exclusiva!</h5>
      <div class="d-flex flex-wrap">
        <div v-for="item in exclusiveLives" :key="item.id">
          <button
            class="btn m-0 p-0 flex-fill"
            @click="() => openLive(item, true)"
          >
            <live-item is-exclusive :item="item" />
          </button>
        </div>
      </div>
    </div>

    <div v-if="!lives.length" class="d-flex justify-content-center my-3">
      <p class="text-secondary">
        Não encontramos lives para esta formação ou filtro aplicado.
      </p>
    </div>

    <div class="row">
      <div
        v-for="(item, i) in lives"
        :key="`course-${i}`"
        class="col-6 col-xs-4 col-md-3 col-xl-2 m-2 mb-4"
        :class="{
          'col-lg-12 col-xl-12': !isGrid,
        }"
      >
        <button class="btn m-0 p-0 flex-fill" @click="openLive(item)">
          <live-item v-if="isGrid" :item="item" />
          <text-item v-else :class="{ 'mt-lg-4 mt-3': i != 0 }" :item="item" />
          <hr v-if="!isGrid" class="bg-dark" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { getPosition } from "@/functions/helpers";
import SalesMixin from "@/functions/mixins/sales";
import LiveItem from "./items/LiveItem.vue";
import TextItem from "./items/TextItem.vue";
import { mapState } from "vuex";

export default {
  mixins: [SalesMixin],
  components: { LiveItem, TextItem },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
    isGrid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("lives", ["lives", "exclusiveLives"]),
  },
  mounted() {
    this.$store.dispatch("lives/getExclusiveLives");
  },
  methods: {
    position: (i) => getPosition(i),
    openLive(item, is_exclusive = undefined) {
      if (
        this.check_live_purchase(item.id) ||
        this.check_formation_purchase(item?.formation?.id)
      ) {
        const past_query = is_exclusive ? { query: { exclusive: "true" } } : {};

        this.$router.push({
          name: "live",
          params: { id: item.id },
          ...past_query,
        });
      } else {
        this.$message.warning(
          "Você ainda não possui a assinatura desta live ou da formação que ela pertence."
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.components-home-live-list {
}
</style>
