import { createStore } from "vuex";

import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import state from "./state";

import notifications from "./notifications";
import categories from "./categories";
import statistics from "./statistics";
import formations from "./formations";
import materials from "./materials";
import community from "./community";
import sessions from "./sessions";
import courses from "./courses";
import modules from "./modules";
import lessons from "./lessons";
import lives from "./lives";
import forum from "./forum";
import user from "./user";
import faq from "./faq";

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    notifications,
    categories,
    statistics,
    formations,
    materials,
    community,
    sessions,
    courses,
    modules,
    lessons,
    forum,
    lives,
    user,
    faq,
  },
});
