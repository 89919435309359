import MaterialButton from "./material/MaterialButton.vue";
import MaterialSelect from "./material/MaterialSelect.vue";
import MaterialModal from "./material/MaterialModal.vue";
import ConfirmationModal from "./ConfirmationModal.vue";
import MaterialCard from "./material/MaterialCard.vue";
import ActionDispatcher from "./ActionDispatcher.vue";
import NumberPagination from "./NumberPagination.vue";
import CoverArea from "./templates/CoverArea.vue";
import CustomCheckBox from "./CustomCheckBox.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import FormGroup from "./FormGroup.vue";
import FileInput from "./FileInput.vue";
import UserPhoto from "./UserPhoto.vue";

export default [
  { name: "ConfirmationModal", component: ConfirmationModal },
  { name: "ActionDispatcher", component: ActionDispatcher },
  { name: "NumberPagination", component: NumberPagination },
  { name: "MaterialButton", component: MaterialButton },
  { name: "CustomCheckBox", component: CustomCheckBox },
  { name: "LoadingSpinner", component: LoadingSpinner },
  { name: "MaterialSelect", component: MaterialSelect },
  { name: "MaterialModal", component: MaterialModal },
  { name: "MaterialCard", component: MaterialCard },
  { name: "FormGroup", component: FormGroup },
  { name: "FileInput", component: FileInput },
  { name: "CoverArea", component: CoverArea },
  { name: "UserPhoto", component: UserPhoto },
];
