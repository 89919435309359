export default {
  API: {
    PARAMS: {
      LIMIT: 16,
    },
  },
  TABS: [
    // {
    //   name: "Comece por aqui",
    //   slug: "start-here",
    //   id: "start-here",
    // },
    {
      name: "Formações",
      slug: "formations",
      id: "formations",
    },
    {
      name: "Lives",
      slug: "lives",
      id: "lives",
    },
    {
      name: "Cursos",
      slug: "courses",
      id: "courses",
    },
    {
      name: "Materiais",
      slug: "materials",
      id: "materials",
    },
  ],
  FORMATION_CONTENT_TABS: [
    {
      name: "Lives",
      slug: "lives",
      id: "lives",
    },
    {
      name: "Cursos",
      slug: "courses",
      id: "courses",
    },
    {
      name: "Materiais",
      slug: "materials",
      id: "materials",
    },
  ],
  SEARCH_TABS: [
    {
      name: "Tudo",
      slug: "all",
      id: "all",
    },
  ],
};
