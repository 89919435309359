export default {
  setActiveSessions(state, active_sessions) {
    state.active_sessions = active_sessions;
  },
  setMyActiveSession(state, my_active_session) {
    state.my_active_session = my_active_session;
  },
  setMySessions(state, my_sessions) {
    state.my_sessions = my_sessions;
  },
  setShowSession(state, show_my_session) {
    state.show_my_session = show_my_session;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
};
