<template>
  <div
    class="components-profile-dropdown"
    :class="{
      'd-flex justify-content-center align-items-center': short,
      'px-2': !short,
    }"
  >
    <div
      v-if="user"
      class="cursor-pointer d-flex align-items-center justify-content-between"
      @click="opened = !opened"
    >
      <div class="pic-wrapper me-2">
        <img v-if="user?.photo" :src="user.photo" class="profile-pic" />
        <div v-else-if="user.name" class="profile-pic letter fw-medium">
          {{ user.name[0] }}
        </div>
        <div class="notification-dot"></div>
      </div>
      <div
        v-if="!short"
        class="user-name"
        :class="{
          'text-primary': opened,
        }"
      >
        {{ user.name }}
      </div>
      <i
        v-if="!short"
        class="icon-chevron-up transform-down"
        :class="{
          'transform-up': opened,
          'text-primary': opened,
        }"
      />
    </div>
    <div v-if="opened" class="pt-4">
      <!-- <router-link class="btn btn-option" :to="{ name: 'notifications' }">
        <span class="flex-fill text-start">Notificações</span>
        <span class="notification-count ms-4 me-2">{{ notifyCount }}</span>
        <i class="icon-bell"></i>
      </router-link> -->
      <router-link
        class="btn btn-option justify-content-start"
        :to="{ name: 'settings' }"
      >
        Configurações
      </router-link>
      <button
        class="btn btn-option justify-content-start"
        @click="logoutDialog = true"
      >
        Sair
      </button>
    </div>
    <confirmation-modal
      v-model="logoutDialog"
      title="Sair"
      text="Deseja mesmo sair?"
      @confirm="logout"
    />
  </div>
</template>
<script>
export default {
  props: {
    short: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logoutDialog: false,
      opened: false,
    };
  },
  watch: {
    short(v) {
      if (v) {
        this.opened = false;
      }
    },
  },
  computed: {
    notifyCount() {
      return this.$store.state.notifications.count;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.components-profile-dropdown {
  .cursor-pointer {
    cursor: pointer;
  }

  .user-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #7d7c8d;
  }
  .pic-wrapper {
    position: relative;
    width: 36px;
    height: 36px;

    .profile-pic {
      border-radius: 50%;
      object-fit: cover;
      width: 36px;
      height: 36px;

      color: #0d0601;
      background-color: #e9e8fb;
      font-weight: bold;

      display: flex;
      justify-content: center;
      align-items: center;

      &.letter {
        border: 1px solid #0d0601;
      }
    }
    .notification-dot {
      border: 1px solid #c6fbd3;
      background: #28ae47;

      border-radius: 50%;
      height: 10px;
      width: 10px;
      position: absolute;
      bottom: 2%;
      right: -1%;

      &.offline {
        border: 1px solid #fbc6cc;
        background: #db5564;
      }
    }
  }

  .transform-down {
    transform: rotate(180deg);
  }

  .transform-up {
    transform: rotate(0deg);
  }

  .icon-chevron-up {
    font-size: 16px;
  }

  .btn-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #7d7c8d;
    box-shadow: none;
    width: 100%;

    i {
      font-size: 16px;
      color: #8d1927;
    }

    &:hover {
      background: #fddce0;
    }

    .notification-count {
      width: 31px;
      height: 26px;
      border-radius: 8px;
      background-color: #fddce0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8d1927;
    }
  }
}
</style>
