export default {
  get(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/statistic/${id}/user`)
        .then((response) => {
          context.commit("setStatistics", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
