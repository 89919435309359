<template>
  <div
    class="avatar-user d-flex justify-content-center align-items-center"
    :style="background"
    :class="[
      {
        'size-sm': size === 'sm',
        'size-md': size === 'md',
        'size-lg': size === 'lg',
      },
    ]"
  >
    {{ letter }}
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    user: {
      required: true,
      type: Object,
    },
    size: {
      required: false,
      default: "md",
      type: String,
    },
  },
  computed: {
    background() {
      return this.user && this.user.photo
        ? `background-image: url(${this.user.photo})`
        : "";
    },
    letter() {
      if (!this.user || this.user.photo) return "";

      return get(this.user, "name", get(this.user, "email", ""))[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-user {
  overflow: hidden;

  border-radius: 50%;

  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;

  color: #000000;

  &.size-sm {
    min-width: 32px;
    min-height: 32px;

    width: 32px;
    height: 32px;

    font-size: 16px;
    line-height: 20px;
  }

  &.size-md {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;

    font-size: 38.5px;
    line-height: 47px;
  }

  &.size-lg {
    min-width: 112px;
    min-height: 112px;
    width: 112px;
    height: 112px;

    font-size: 38.5px;
    line-height: 47px;
  }

  background-color: #e9e8fb;
  border: 1px solid #d4d3ea;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
