<template>
  <div
    v-if="showNotifications"
    class="d-flex align-items-center fw-medium pt-4 mb-4 mb-lg-4"
  >
    <i
      v-if="exist_notification"
      @click="showNotifications = false"
      class="icon-bell-off me-3"
    ></i>
    <span
      v-if="notifications?.live?.published_at"
      class="dot bg-danger me-2"
    ></span>
    <span v-if="notifications?.live?.published_at" class="text-danger me-3">
      Live · {{ format_date(notifications.live.published_at) }}
    </span>
    <router-link
      v-if="isOnLiveNow"
      :to="{
        name: 'live',
        params: { id: isOnLiveNow?.id },
        query: { exclusive: 'true' },
      }"
      class="text-danger border-danger me-4"
    >
      Assista Agora!
    </router-link>

    <i
      v-if="notifications?.copybook?.published_at"
      class="icon-book text-success me-3"
    ></i>
    <span v-if="notifications?.copybook?.published_at" class="text-success">
      Caderno de ativação {{ copybookDate }}
    </span>
  </div>
  <div v-else></div>
</template>
<script>
import { removeTimeZone } from "@/functions/helpers.js";
import moment from "moment";

export default {
  data: () => ({
    showNotifications: true,
    notifications: null,
    isOnLiveNow: null,
    live_lesson: null,
  }),
  mounted() {
    this.$store.dispatch("notifications/getAll").then((data) => {
      this.notifications = data;
    });
    this.$store.dispatch("lives/getExclusiveLives").then((response) => {
      this.isOnLiveNow = response.results[0];
    });
  },
  methods: {
    format_date(date) {
      if (date) {
        return moment(removeTimeZone(date)).format("DD/MMM[,] HH:mm");
      } else {
        return "";
      }
    },
  },
  computed: {
    exist_notification() {
      return (
        this.notifications?.live?.published_at ||
        this.notifications?.copybook?.published_at
      );
    },
    liveDate() {
      return this.format_date(this.notifications.live.published_at);
    },
    copybookDate() {
      return this.format_date(this.notifications.copybook.published_at);
    },
  },
};
</script>
<style lang="scss" scoped>
.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.icon-bell-off {
  cursor: pointer;
}

.border-danger {
  border: 1px solid #c83b4c;
  padding: 8px;
}

@keyframes fade_out_in {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// .icon-bell1 {
//   animation: fade_out_in 2s infinite;
// }
</style>
