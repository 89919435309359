<template>
  <div class="component-file-input">
    <div v-if="innerValue" class="preview p-4">
      <img :src="innerValue.base64" class="flex-fill img-fluid mb-2" />
      <button
        class="btn btn-remove text-danger m-0 p-0 btn-no-shadows"
        @click="innerValue = null"
      >
        Remover <i class="icon-trash ms-1"></i>
      </button>
    </div>
    <div v-else class="file-input form-group position-relative border-none">
      <button
        type="button"
        class="btn btn-block text-start border-none"
        @click="openSelectBox"
      >
        <div class="d-flex flex-column py-3 py-lg-5">
          <img
            src="@/assets/images/icon-input.png"
            class="img-fluid"
            alt="Icones input"
          />
          <div class="d-flex justify-content-center py-4">
            <button class="btn btn-no-shadows text-primary">
              <i :class="`${icon} me-2`"></i>
              {{ label || "Carregar projeto favorito" }}
            </button>
          </div>
        </div>
        <input
          :id="id"
          type="file"
          :accept="acceptedFileType"
          @change="inputFileChanged"
          :multiple="multiple"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { toBase64 } from "@/functions/helpers";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noPreview: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "icon-upload",
    },
    lightLabel: {
      type: String,
      default: "",
    },
    file_count_limit: {
      type: Number,
      default: 5,
    },
    file_size_limit: {
      type: Number,
      default: 30, // Size in MB
    },
    acceptedFileType: {
      type: String,
      default: "image/*, video/*, audio/*, .pdf",
    },
    modelValue: {
      type: [File, String, Array],
      default: null,
    },
  },
  data() {
    return {
      innerValue: this.modelValue,
    };
  },
  watch: {
    innerValue(newVal) {
      if (newVal != this.modelValue) {
        this.$emit("update:modelValue", newVal);
      }
    },
    modelValue(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
      }
    },
  },
  methods: {
    remove(index) {
      this.innerValue = this.innerValue.filter((_, i) => i !== index);
    },
    openSelectBox() {
      document.getElementById(this.id).click();
    },
    async inputFileChanged(event) {
      if (event.target.files && event.target.files.length) {
        if (event.target.files.length > this.file_count_limit) {
          this.$message.error(
            `Escolha no máximo 5 arquivos com tamanho total máximo de ${this.file_size_limit}MB`
          );
          return;
        }

        const arrFiles = Array.from(event.target.files);
        const files = arrFiles.map((file) => {
          const src = window.URL.createObjectURL(file);
          return { file, src };
        });

        try {
          if (this.multiple) {
            let file_list = [];
            for (let i in files) {
              const base64 = await toBase64(files[i].file);
              const file_size = files[i].file.size / 1024 / 1024;

              if (file_size > this.file_size_limit) {
                this.$message.warning(
                  `Seus arquivos não deve ultrapassar ${this.file_size_limit}MB.`
                );
                break;
              }

              file_list.push({
                ...files[i],
                base64,
              });
            }
            this.$emit("update:modelValue", file_list);
            return;
          }
          const file_size = files[0].file.size / 1024 / 1024;

          if (file_size <= this.file_size_limit) {
            const base64 = await toBase64(files[0].file);
            this.$emit("update:modelValue", {
              ...files[0],
              base64,
            });
          } else {
            this.$message.warning(
              `Seu arquivo não deve ultrapassar ${this.file_size_limit}MB.`
            );
          }
          return;
        } catch (error) {
          this.$message.error(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.component-file-input {
  .btn-remove {
    i {
      font-size: 12px;
    }
  }
  .file-input {
    width: 279px;
    background: #f0eeff;
    border: 1px solid #e9e8fb;
    border-radius: 8px;

    @media screen and (min-width: 992px) {
      aspect-ratio: 279/283;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    .btn {
      padding: 0;
      border: none;
      background: transparent;
      box-shadow: none;
    }

    .placeholder {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #202124;
      display: flex;
      align-items: center;
      .btn {
        font-size: 16px;
        min-height: 5px;
        min-width: 10px;
        line-height: 5px;
        margin: 0;
        padding: 0;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }

    i {
      font-size: 26px;
      color: #28ae47;
    }
    .image {
      max-height: 80px;
      max-width: 80px;
      min-height: 80px;
      min-width: 80px;
      border-radius: 80px;
      object-fit: cover;
      box-shadow: 0 0 16px rgba(100, 100, 100, 0.1);
    }

    input {
      position: absolute;
      visibility: hidden;
      width: 1px;
    }

    label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #595959;
    }

    .file-indicator-wrapper {
      background: rgba(32, 33, 36, 0.05);
      border: 1px solid rgba(32, 33, 36, 0.5);
      border-radius: 4px;
      min-height: 48px;
      height: 48px;
      width: 100%;
      @media screen and (max-width: 768px) {
        height: fit-content;
      }
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      .file-name {
        background: #8c8c8c;
        border-radius: 16px;
        max-width: 228px;
        font-size: 12px;
        color: #fff;

        span {
          max-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .btn-outline-success {
        background: transparent;
        color: #28ae47;
        border: 1px solid #9a99ae;
        border-radius: 12px;
        transition: filter 5ms;
        &:hover {
          filter: brightness(80%);
        }
      }
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-height: 180px;
      object-fit: contain;
    }
  }
}
</style>
