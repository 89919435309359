<template>
  <div
    class="components-material-card"
    :class="{
      'box-shadows': boxShadows,
    }"
  >
    <MDBCard :bg="transparent">
      <MDBCardBody :style="[isSession ? 'padding: 0px !important; ' : '']">
        <slot />
      </MDBCardBody>
    </MDBCard>
  </div>
</template>

<script>
import { MDBCard, MDBCardBody } from "mdb-vue-ui-kit";
export default {
  props: {
    isCardBg: {
      type: Boolean,
      default: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    boxShadows: {
      type: Boolean,
      default: false,
    },
    isSession: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MDBCard,
    MDBCardBody,
  },
  computed: {
    transparent() {
      if (!this.isTransparent) {
        return "white";
      } else {
        return "transparent";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.components-material-card {
  ::v-deep .card {
    background: #fcfcfc;
    border: 1px solid #d4d3ea;
    border-radius: 12px;
    box-shadow: none;
  }
  &.box-shadows {
    ::v-deep .card {
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
