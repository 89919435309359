export default {
  get(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/categories?limit=999999")
        .then((response) => {
          context.commit("setCategories", response.data.results);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
