<template>
  <button
    v-if="backToLesson"
    class="btn btn-back-component m-0 p-0"
    @click="historyData.back"
  >
    Aula
    <i class="ms-2 icon-arrow-right"></i>
  </button>
  <span v-else></span>
</template>
<script>
export default {
  computed: {
    backToLesson() {
      return (
        this.$route.path.indexOf("/aula/") == -1 &&
        window.history?.state?.back?.indexOf("/aula/") > -1
      );
    },
    historyData() {
      return window.history;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-back-component {
}
</style>
