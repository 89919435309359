<template>
  <div class="template-auth">
    <div class="slot-wrapper py-5">
      <slot />
      <!-- <div class="small text-secondary mt-5">
        Este site é protegido por reCAPTCHA e a
        <a target="_blank" href="https://policies.google.com/privacy"
          >Política de Privacidade</a
        >
        e os
        <a target="_blank" href="https://policies.google.com/terms"
          >Termos de Serviço</a
        >
        do Google se aplicam.
      </div> -->
    </div>
    <div class="flex-fill"></div>
    <div class="img-bg bg-dark"></div>
  </div>
</template>

<style lang="scss" scoped>
.template-auth {
  position: relative;
  min-height: 100vh;
  display: flex;
  //box-shadow: 0px 0px 112px 24px rgba(92, 70, 54, 0.7);

  .slot-wrapper {
    width: 80%;
    border-radius: 4px;
    //max-width: 432px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-bg {
    width: 50%;
    //background-color: #000;
    background: url(../assets/images/auth/bg_auth.jpg),
      linear-gradient(180deg, #e2c3ad 0%, #4a4a4e 69.3%);
    background-repeat: no-repeat, repeat;
    background-size: cover;
    box-shadow: inset 30px 0px 112px 24px rgba(92, 70, 54, 0.7);
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;

    .slot-wrapper {
      margin: auto;
      //width: 40%;
    }

    .img-bg {
      display: none;
    }
  }
}
</style>
