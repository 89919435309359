<template>
  <section
    :class="`cover-area ${size} d-flex flex-column justify-content-center pt-lg-0`"
    :style="backgroundStyle"
  >
    <slot></slot>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator: (value) => ["md", "lg"].indexOf(value.toLowerCase()) > -1,
    },
    cover: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    backgroundStyle() {
      return `
        background-image: url(${this.cover_url});
        background-repeat: no-repeat;
        background-size: cover;
      `;
    },
    cover_url() {
      if (this.cover) return this.cover;

      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.cover-area {
  background-position: center center;
  background-color: #f4bf9b;

  &.md {
    min-height: 200px;
  }

  &.lg {
    min-height: 300px;
    height: 100%;
  }
  &.xl {
    min-height: 515px;
    flex-direction: row !important;
    @media screen and (max-width: 756px) {
      min-height: 350px;
    }
  }

  @media screen and (max-width: 576px) {
    min-height: 150px !important;
  }
}
</style>
