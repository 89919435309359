<template>
  <div>
    <div v-if="!courses.length" class="d-flex justify-content-center my-3">
      <p class="text-secondary">
        Sem cursos para esssa formação ou filtro aplicado
      </p>
    </div>

    <div class="mb-4">
      <div class="row">
        <div
          v-for="(item, index) in courses"
          :key="`course-${index}`"
          class="col-lg-6 col-xl-4 mb-4"
          :class="{
            'col-lg-12 col-xl-12': !isGrid,
          }"
        >
          <div
            class="d-flex"
            :class="{
              'justify-content-xl-end': position(index) == 'end' && isGrid,
              'justify-content-xl-start': position(index) == 'start' && isGrid,
              'justify-content-center': isGrid,
            }"
          >
            <button class="btn m-0 p-0 flex-fill" @click="openCourse(item)">
              <course-card-item v-if="isGrid" :item="item" />
              <text-item v-else :item="item" type="course" />
              <hr
                v-if="!isGrid && index < courses.length - 1"
                class="bg-dark"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseCardItem from "./items/CourseCardItem.vue";
import { getPosition } from "@/functions/helpers";
import TextItem from "./items/TextItem.vue";
import { mapState } from "vuex";
import SalesMixin from "@/functions/mixins/sales";

export default {
  mixins: [SalesMixin],
  components: { TextItem, CourseCardItem },
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("courses", ["courses"]),
  },
  methods: {
    position: (i) => getPosition(i),
    openCourse(item) {
      if (
        this.check_course_purchase(item.id) ||
        this.check_formation_purchase(item?.formation?.id)
      )
        this.$router.push({ name: "course", params: { id: item.id } });
      else
        this.$message.warning(
          "Você ainda não possui a assinatura deste curso."
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.grid-size {
  height: 340px;
  @media screen and (max-width: 991px) {
    height: 150px;
  }
}
.text-size {
  height: 80px;
}
</style>
