<template>
  <div class="loading">
    <span v-if="!show">
      <slot />
    </span>
    <div v-if="show" class="lds-mdb px-4 py-1">
      <MDBSpinner :size="size" />
    </div>
  </div>
</template>

<script>
import { MDBSpinner } from "mdb-vue-ui-kit";
export default {
  name: "LoadingSpinner",
  components: { MDBSpinner },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
      validator: (value) =>
        ["sm", "md", "lg"].indexOf(value.toLowerCase()) > -1,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
