<template>
  <div class="finish-modal-session p-4">
    <div class="d-flex justify-content-between align-items-center d-lg-none">
      <p>
        Missão cumprida! Você ficou mais<br />
        forte!
      </p>
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-close btn-no-shadows mt-n5"
      ></button>
    </div>
    <div class="py-3 mb-5 d-lg-none"></div>
    <div class="description-wrapper p-3">
      <div class="finish-infos">
        {{ duration }}
        <span class="green-box mx-1">+ {{ currentDuration }}</span>
        {{ today }}
      </div>
      <p>
        <i :class="`${session.icon || 'icon-gym-weight'}`"></i>
        {{ session.title }}
      </p>
    </div>
    <div
      class="d-flex flex-lg-row flex-column align-items-center justify-content-end pt-4"
    >
      <button
        class="btn btn-course btn-outline-secondary text-primary px-3 mobile-w100 mb-lg-0 mb-4"
        @click="$emit('close')"
      >
        Continuar
      </button>
      <button
        class="btn btn-primary px-3 mobile-w100"
        @click="$emit('open-step-2')"
      >
        Finalizar sessão
      </button>
    </div>
  </div>
</template>
<script>
import SessionTimeMixin from "@/functions/mixins/sessionTime.js";
import moment from "moment";

export default {
  mixins: [SessionTimeMixin],
  props: ["session"],
  computed: {
    today() {
      return moment().format("DD MMM YYYY");
    },
    duration() {
      if (this.session.duration) return this.session.duration;

      return "";
    },
  },
  mounted() {
    this.loadMinutes();
  },
};
</script>
<style lang="scss" scoped>
.finish-modal-session {
  .finish-infos {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.4px;
    color: #33333a;

    display: flex;
    align-items: center;
    .green-box {
      color: #0e6623;
      background: #c6fbd3;
      border-radius: 8px;
      padding: 4px 5px;
    }
  }
}
</style>
