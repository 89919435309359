<template>
  <section class="components-home-content-area">
    <tab-bar
      :tabs="tabs"
      :search="search"
      :is-grid="isGrid"
      :formation="formation"
      :active_tab="active_tab"
      @change-tab="onChangeTab"
      @change-grid="onChangeGrid"
      @submit-search="submitSearch"
      @change-search="onChangeSearch"
    />

    <div v-if="loading" class="d-flex justify-content-center py-5">
      <div class="spinner-border text-primary"></div>
    </div>
    <template v-else>
      <search-list
        v-if="active_tab == 'all'"
        :list="search_results"
        :is-grid="isGrid"
        :params="params"
      />

      <start-here-list
        v-if="active_tab == 'start-here'"
        :is-grid="isGrid"
        :params="params"
      />

      <live-list
        v-if="active_tab == 'lives'"
        :is-grid="isGrid"
        :params="params"
      />

      <course-list
        v-if="active_tab == 'courses'"
        :is-grid="isGrid"
        :params="params"
      />

      <formation-list
        v-if="active_tab == 'formations'"
        :is-grid="isGrid"
        :params="params"
      />

      <material-list v-if="active_tab == 'materials'" :params="params" />

      <div class="d-flex justify-content-center pt-4">
        <number-pagination v-model="params" :count="count" @refresh="refresh" />
      </div>
    </template>
  </section>
</template>
<script>
import NumberPagination from "@/components/NumberPagination.vue";
import TabBar from "@/components/home/TabBar.vue";
import FormationList from "./FormationList.vue";
import StartHereList from "./StartHereList.vue";
import MaterialList from "./MaterialList.vue";
import CourseList from "./CourseList.vue";
import SearchList from "./SearchList.vue";
import LiveList from "./LiveList.vue";

import ContentMixin from "@/functions/mixins/content.js";

export default {
  mixins: [ContentMixin],
  components: {
    NumberPagination,
    FormationList,
    StartHereList,
    MaterialList,
    CourseList,
    SearchList,
    LiveList,
    TabBar,
  },
};
</script>
<style lang="scss" scoped>
.components-home-content-area {
}
</style>
