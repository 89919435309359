<template>
  <div class="finish-session-step-2 row p-4">
    <div class="d-flex justify-content-between d-lg-none">
      <p class="flex-fill" @click="$emit('return')">
        <i class="icon-angle-left me-2" />
        Detalhes da sessão
      </p>
      <button
        type="button"
        @click="$emit('close')"
        class="btn-close btn-no-shadows"
      ></button>
    </div>
    <div class="pb-1 d-lg-none"></div>
    <div class="col-lg-5 pe-lg-2">
      <div class="date-infos mb-2 d-lg-none">
        {{ session.duration.slice(0, 2) }} h
        {{ session.duration.slice(3, 5) }} min
        <span class="dot mx-1"></span>
        {{ today }}
      </div>
      <p class="mb-4 d-lg-none">
        <i :class="`${session.icon || 'icon-gym-weight'} me-1`"></i>
        {{ session.title }}
      </p>
      <file-input
        id="archives-session-strength"
        v-model="form.file"
        label="Anexar mídia"
        icon="icon-link1"
        class="file"
      />
    </div>
    <div class="col-lg-7 ps-lg-2 py-3 py-lg-0">
      <div class="date-infos mb-3 d-none d-lg-block">
        {{ session.duration.slice(0, 2) }} h
        {{ session.duration.slice(3, 5) }} min
        <span class="dot mx-1"></span>
        {{ today }}
      </div>
      <p class="mb-4 d-none d-lg-block">
        <i :class="`${session.icon || 'icon-gym-weight'} me-1`"></i>
        {{ session.title }}
      </p>
      <div class="form-group mb-2">
        <textarea
          id="about"
          v-model="form.about"
          placeholder="Como foi sua sessão de fortalecimento? Teve progresso? Aprendeu algo novo? Este aqui é seu espaço privado, como se fosse um diário. Fique à vontade! (Opcional)"
          class="form-control"
          cols="12"
          rows="8"
        >
        </textarea>
      </div>
      <div class="d-flex justify-content-end mb-5">
        <small
          :class="`fw-bold text-dark ${
            form.about.length <= 500 ? 'text-secondary' : 'text-danger'
          }`"
        >
          {{ form.about.length }}/500
        </small>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="finish">
          <loading-spinner :show="loading" size="sm">
            Finalizar
          </loading-spinner>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { formFields } from "@/functions/helpers";
import moment from "moment";

export default {
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    today: {
      type: String,
      default: moment().format("DD MMM YYYY"),
    },
  },
  data() {
    return {
      ...formFields(["about", "file"]),
      loading: false,
    };
  },
  methods: {
    finish() {
      const payload = { ...this.form };

      if (payload.about.length > 500) {
        this.$message.warning("Use uma descrição de até 500 caracteres.");
        return;
      }

      this.loading = true;

      if (!payload.file) {
        delete payload.file;
      }
      let date = new Date().toISOString();
      payload.finished_at = date;

      this.$store
        .dispatch("sessions/finish", {
          id: this.session.id,
          payload: payload,
        })
        .then(() => {
          this.$message.success("Sua sessão foi finalizada.");
          this.$emit("close");

          this.$store.dispatch("sessions/get", true).then();
          setTimeout(() => {
            this.$router.push("/minhas-sessoes");
          }, 1000);
        })
        .catch(() => {
          this.$message.error("Não foi possível finalizar a sessão.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.finish-session-step-2 {
  display: flex;
  align-items: center;
  .date-infos {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.4px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #33333a;

    display: flex;
    align-items: center;

    .dot {
      height: 1px;
      width: 1px;
      background: #33333a;
    }
  }
  textarea {
    @media screen and (max-width: 991px) {
      max-width: 100%;
      font-size: 12px;
      max-height: 115px;
    }
  }

  p {
    color: #33333a;
  }
  .btn {
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
</style>
