<template>
  <div v-if="isOptions" class="component-select-input">
    <div v-if="innerValue" class="d-flex align-items-center">
      <button
        class="btn btn-value-filled p-0 m-0 me-1"
        @click="innerValue = null"
      >
        <span>{{ selected }} <i class="icon-close ms-2"></i></span>
      </button>
      <button class="btn btn-placeholder p-0 m-0" @click="show = true">
        <i class="icon-angle-down ms-2"></i>
      </button>
    </div>

    <button v-else class="btn btn-placeholder" @click="show = true">
      {{ placeholder }} <i class="icon-angle-down ms-2"></i>
    </button>

    <div class="dropdown-menu p-2" :class="{ show }">
      <button
        v-for="({ name, id }, i) in options"
        :key="`${name}${i}`"
        class="btn dropdown-item"
        @click="onSelect(id)"
      >
        {{ name }}
      </button>
      <p v-if="!options.length" class="text-secondary pt-2">
        <small>{{ emptyMsg }}</small>
      </p>
    </div>
  </div>
  <!-- this code is for painel's mobile version -->
  <div class="component-select-input home-input mt-3" v-else>
    <div
      v-if="innerValue"
      class="d-flex align-items-center justify-content-between ms-0 pe-3"
    >
      <div class="btn btn-value-filled-home m-0 p-0">
        <span>{{ selected }}</span>
      </div>

      <button class="btn btn-placeholder p-0 m-0" @click="show = true">
        <i class="icon-angle-down ms-2"></i>
      </button>
    </div>

    <button v-else class="btn btn-placeholder" @click="show = true">
      {{ placeholder }} <i class="icon-angle-down ms-2"></i>
    </button>

    <div
      class="dropdown-menu-home p-2 w-100"
      v-click-outside="close"
      :class="{ show }"
    >
      <button
        v-for="({ name, id }, i) in options"
        :key="`${name}${i}`"
        class="btn dropdown-item"
        @click="onSelect(id)"
      >
        {{ name }}
      </button>
      <p v-if="!options.length" class="text-secondary pt-2">
        <small>{{ emptyMsg }}</small>
      </p>
    </div>
  </div>
</template>
<script>
import vClickOutside from "click-outside-vue3";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    emptyMsg: {
      type: String,
      default: "Sem itens no momento",
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerValue: this.modelValue,
      show: false,
    };
  },
  watch: {
    innerValue(v) {
      this.$emit("update:modelValue", v);
    },
  },
  computed: {
    selected() {
      const item = this.options.find(({ id }) => id == this.innerValue);
      return item ? item.name : this.placeholder;
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    onSelect(id) {
      this.innerValue = id;
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.component-select-input {
  position: relative;

  font-style: normal;
  box-shadow: none;
  border: none;

  @media screen and (min-width: 992px) {
    border-radius: 0;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-bottom-color: #33333a10;
  }

  .btn-placeholder {
    color: #33333a;
    background: transparent;
    min-width: fit-content;
    height: 40px;
    font-size: 13px !important;
    margin-right: 0;
    margin-left: 0;

    &:hover {
      box-shadow: none;
    }

    i {
      font-size: 12px;
    }
  }

  .btn-value-filled {
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.01em;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #33333a;
      background: #9bf4b0;
      border-radius: 8px;
      padding: 7px 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 9px;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-menu {
    background: #f2fff5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

    max-height: 300px;
    overflow-y: scroll;
    overflow-x: clip;

    position: absolute;
    top: 90%;
    left: 0;
    display: none;
    height: 0;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &.show {
      display: block;
      height: fit-content;
    }
  }

  .dropdown-item {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.0025em;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #33333a;
    text-align: left;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
  }

  small {
    font-size: 10px;
  }
}
// this code is for painel mobile version
.home-input {
  background: #fcfcfc;
  border-radius: 12px;
  .btn-value-filled-home {
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.0025em;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #5a5966;
      border-radius: 8px;
      padding: 7px 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 9px;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-menu-home {
    background: #fcfcfc;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    z-index: 99999;

    max-height: 300px;
    overflow-y: scroll;
    overflow-x: clip;

    position: absolute;
    top: 90%;
    left: 0;
    display: none;
    height: 0;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &.show {
      display: block;
      height: fit-content;
    }
  }

  .dropdown-item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.0025em;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #5a5966;
    text-align: left;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
  }
}
</style>
