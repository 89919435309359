<template>
  <div class="components-home-materials-list">
    <div v-if="!materials.length" class="d-flex justify-content-center my-3">
      <p class="text-secondary">
        Não encontramos materiais para esta formação ou filtro aplicado.
      </p>
    </div>

    <div class="row">
      <div
        v-for="(item, i) in materials"
        :key="`material${i}`"
        class="col-xl-2 col-lg-3 col-md-4 col-6 py-2"
      >
        <material-item :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialItem from "./items/MaterialItem.vue";
import { mapState } from "vuex";
export default {
  components: { MaterialItem },
  computed: {
    ...mapState("materials", ["materials"]),
  },
};
</script>

<style lang="scss" scoped>
.components-home-materials-list {
}
</style>
