export default {
  setFormations(state, formations) {
    state.formations = formations;
  },
  setFormation(state, formation) {
    state.formation = formation;
  },
  setCount(state, count) {
    state.count = count;
  },
};
