export default {
  get(context, params = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(params).toString();
      window.axios
        .get("/contents/lessons?" + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setLessons", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  getLesson(context, { id, isStartHere }) {
    let path = `/contents/${id}/lesson`;

    if (isStartHere) {
      path = `/contents/lesson/${id}/start-here`;
    }

    return new Promise((resolve, reject) => {
      window.axios
        .get(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getStartHere(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/lessons/start-here`)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setStartHereLessons", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  progress(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/progress/course`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getLiveLesson(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/lessons/live`)
        .then((response) => {
          context.commit("setLiveLessons", response.data.results);
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },
};
