/* eslint-disable */
export function toHumanDate(date) {
  return date
    .split("-")
    .reverse()
    .join("/");
}

export function dayDiff(dateString) {
  var today = new Date();
  var date = new Date(dateString);

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export function isMobile() {
  if (window.innerWidth < 991) {
    return true;
  } else {
    return false;
  }
}

export const emojis = [
  "😁",
  "😂",
  "😃",
  "😄",
  "😅",
  "😆",
  "😉",
  "😊",
  "😋",
  "😌",
  "😍",
  "😏",
  "😒",
  "😓",
  "😔",
  "😖",
  "😘",
  "😚",
  "😜",
  "😝",
  "😞",
  "😠",
  "😢",
  "😤",
  "😥",
  "😨",
  "😩",
  "😪",
  "😫",
  "😭",
  "😰",
  "😱",
  "😲",
  "😳",
  "😵",
  "😷",
  "😸",
  "😹",
  "😺",
  "😻",
  "😼",
  "😽",
  "😾",
  "😿",
  "🙀",
  "🙅",
  "🙆",
  "🙇",
  "🙈",
  "🙉",
  "🙊",
  "🙋",
  "🙌",
  "🙍",
  "🙎",
  "🙏"
];

export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] = initial && initial[field] ? initial[field] : "";
    errors[field] = [];
  }
  return { form, errors, loading: false };
};


export const removeMask = maskedValue => {
  return maskedValue
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/\D/g, "")
    .trim();
};

export const getYearRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const clearObject = object => {
  for (const i in object) {
    if (object[i] === "" || object[i] === undefined || object[i] === null) {
      delete object[i];
    }
  }
  return object;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


export function isValidCPF(cpf) {
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split("");
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el);
  const toValidate = pop =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map(el => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}

export function isValidCNPJ(cnpj) {
  const format = value => value.replace(/[^\d]+/g, "");
  const isValidNumber = (value, count) =>
    format(value).length === count && !format(value).match(/(\d)\1{10}/);
  const sum = (array, start) =>
    array.reduce((total, el, i) => total + el * (start - i), 0);
  const rest = value => value % 11;

  const validator = value =>
    format(value)
      .split("")
      .splice(format(value).length - 2)
      .map(el => +el);

  const toValidate = (value, end, start = 0) =>
    format(value)
      .split("")
      .filter((digit, index, array) => index >= start && index <= end && digit)
      .map(el => +el);

  const validate = (firstDigit, lastDigit, validator) =>
    firstDigit === validator[0] && lastDigit === validator[1];

  if (!isValidNumber(cnpj, 14)) return false;

  const digit = sum => (rest(sum) < 2 ? 0 : 11 - rest(sum));

  const firstDigit = digit(
    sum(toValidate(cnpj, 3), 5) + sum(toValidate(cnpj, 11, 4), 9)
  );
  const lastDigit = digit(
    sum(toValidate(cnpj, 4), 6) + sum(toValidate(cnpj, 12, 5), 9)
  );

  return validate(firstDigit, lastDigit, validator(cnpj));
}

export function createObjectURL(blob) {
  const URL_API = URL || webkitURL;

  return URL_API.createObjectURL(blob);
}

export function revokeObjectURL(url_) {
  const URL_API = URL || webkitURL;

  return URL_API.revokeObjectURL(url_);
}

export const cast_to_time = (minutes) => {
  const hour = Math.floor(minutes/60);
  return `${hour < 10 ? '0' + hour : hour}:${minutes % 60}:00`;
}


export const getArrayOfDaysInMonth = (year, month) => {
  var numDaysInMonth, daysInWeek, daysIndex, index, day, daysArray;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  daysInWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  let yearPrepared = month === 1 ? year - 1 : year;

  index =
    daysIndex[new Date(yearPrepared, month - 1, 1).toString().split(" ")[0]];

  daysArray = [];

  for (day = 1; day <= numDaysInMonth[month - 1]; day++) {
    if (day === 1) {
      const end = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = end - 1; weekIndex >= 0; weekIndex--) {
        const beforeMonthDaysCount =
          month === 1 ? numDaysInMonth[11] : numDaysInMonth[month - 2];
        daysArray.push({
          day: beforeMonthDaysCount - weekIndex,
          isCurrentMonth: false,
          monthPosition: "before"
        });
      }

      daysArray.push({ day, isCurrentMonth: true });
    } else if (day === numDaysInMonth[month - 1]) {
      daysArray.push({ day, isCurrentMonth: true, monthPosition: "current" });

      const start = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = 1; weekIndex <= 7 - start; weekIndex++) {
        daysArray.push({
          day: weekIndex,
          isCurrentMonth: false,
          monthPosition: "next"
        });
      }
    } else {
      daysArray.push({ day, isCurrentMonth: true });
    }

    index++;
    if (index == 7) index = 0;
  }

  return daysArray;
};

export const removeTimeZone = function(date_str) {
  if (date_str && date_str[date_str.length - 1].toLowerCase() == "z") {
    return date_str.slice(0, date_str.length - 1);
  }
  return date_str;
};


export const get_time_diff_duration = (date_1, date_2) => {
  const moment = require("moment");
  const created = moment(removeTimeZone(date_1));
  const now = moment(removeTimeZone(date_2));
  return moment.duration(now.diff(created))
};

export const build_date = (day, month, year) => {
  const fDay = day < 10 ? `0${day}` : day;
  const fMonth = month < 10 ? `0${month}` : month;
  return `${fDay}/${fMonth}/${year}`;
};

export const string_to_array = (string_array) => string_array.replace("[", "")
          .replace("]", "")
          .split(",");

export const to_singular = (str) => str.slice(
    0,
    str.length - 1
  );


export function uniq_community_iterable(item){
  if (item.serie) return `serie-${item.serie.id}`;
  if (item.challenge) return `challenge-${item.challenge.id}`;
  if (item.session) return `session-${item.session.id}`;
  if (item.lesson) return `lesson-${item.lesson.id}`;
  if (item.copybook) return `copybook-${item.copybook.id}`;
}

export function adapt_global_results(data) {
  const results = [];
  let count, previous, next = 0;
  
  for (let key in data) {
    results[key] = data[key].results || data[key];

    if (results[key]) {
      /* 
      * The keys are in plural
      * For this reason was used slice to remove `s`
      */
      results[key] = results[key].map(i => i[`${key.slice(0, key.length - 1)}_id`]);
    }

    previous = data[key].previous;
    count = data[key].count;
    next = data[key].next;
  }

  return { results, count, next, previous };
}

export function adapt_first_sessions(list) {
  /*
    This code check if session is the first of the day
    and return the item in a new array, with a properties that
    tells you if is the first section of the day

    Obs: may this code need to be refactored latter
  */
  const moment = require("moment");
  let first = null;

  return list.map((item, i) => {
    if (i == list.length - 1) {
      first = null;
      return { ...item, is_first_session_of_day: false };
    }

    if (!first) {
      first = item;
      return { ...item, is_first_session_of_day: true };
    }

    if (
      moment(removeTimeZone(first.start_at)).isSame(
        moment(removeTimeZone(item.start_at)),
        "day"
      )
    ) {
      return { ...item, is_first_session_of_day: false };
    }

    first = item;
    return { ...item, is_first_session_of_day: true };
  })
}

export function get_youtube_video_id (url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

export function normalizeText(txt) {
  return `${txt}`
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

export function all_content_adapter(general_list) {
  return [
    ...general_list["my_sessions"].map((i) => ({
      ...i,
      bond_type: "session",
      name: `Sessão: ${i.title}`,
    })),
    ...general_list["courses"].map((i) => ({
      ...i,
      bond_type: "course",
      name: `Curso: ${i.title}`,
    })),
    ...general_list["lessons"].map((i) => ({
      ...i,
      bond_type: "lesson",
      name: `Aula: ${i.name}`,
    })),
    ...general_list["materials"].map((i) => ({
      ...i,
      bond_type: "futher_material",
      name: `Material: ${i.title}`,
    })),
    ...general_list["lives"].map((i) => ({
      ...i,
      bond_type: "live",
      name: `Live: ${i.title || i.name}`,
    })),
  ];
}

export function getPosition(i) {
  if (i == 0 || i % 3 == 0) return "start";

  if (i == 1 || i % 3 == 1) return "middle";

  if (i == 2 || i % 3 == 2) return "end";
}


export function object_to_array(list) {
  var isObject = require("lodash/isObject");
  let array = [];
  if (isObject(list)) {
    for (let k in list) array.push(list[k]);
  } else {
    array = list;
  }

  return array;
}


export function handle_http (url) {
  if (url.match("https://")) return url;

  if (url.match("http://")) return url.replace("http://", "https://");

  return `https://${url}`;
}