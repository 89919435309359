export default {
  get(context, params = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(params).toString();
      window.axios
        .get("/contents/lives?" + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit(
            "setLives",
            results.map((r) => ({
              ...r,
              type: r?.type || "lives",
            }))
          );
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  getLive(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/${id}/live`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  setProgress(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/progress/live`, data)
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    });
  },

  getExclusiveLives(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/exclusive-lives`)
        .then((response) => {
          context.commit("setExclusiveLives", response.data.results);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  getExclusive(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`contents/${id}/exclusive-live`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
