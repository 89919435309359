<template>
  <MDBModal
    v-model="innerVisible"
    :centered="centered"
    :size="size"
    :removeBackdrop="removeBackdrop"
    :staticBackdrop="staticBackdrop"
    :duration="duration"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :animation="animation"
    :dialogClasses="dialogClasses"
    :focus="focus"
  >
    <div class="modal-content-wrapper">
      <button
        class="btn btn-modal-close d-none d-lg-block"
        @click="innerVisible = false"
      >
        <i class="icon-close"></i>
      </button>
      <slot></slot>
    </div>
  </MDBModal>
</template>

<script>
import { MDBModal } from "mdb-vue-ui-kit";
export default {
  components: { MDBModal },
  name: "ConfirmationModal",
  props: {
    modelValue: Boolean,
    size: {
      type: String,
      default: "lg",
      validator: (value) =>
        ["sm", "md", "lg", "xl"].indexOf(value.toLowerCase()) > -1,
    },
    removeBackdrop: {
      type: Boolean,
      default: false,
    },
    staticBackdrop: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 400,
    },
    fullscreen: {
      type: [Boolean, String],
      default: false,
    },
    animation: {
      type: Boolean,
      default: true,
    },
    dialogClasses: {
      type: String,
    },
    focus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerVisible: this.modelValue,
    };
  },
  watch: {
    modelValue(v) {
      this.innerVisible = v;
    },
    innerVisible(v) {
      this.$emit("update:modelValue", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content-wrapper {
  position: relative;
  .btn-modal-close {
    position: absolute;
    top: -9%;
    right: 0;
    i {
      font-size: 12px !important;
      color: #fff;
    }
  }
}
</style>
