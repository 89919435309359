<template>
  <material-card>
    <div
      class="components-info-box d-flex flex-column flex-lg-row align-items-center justify-content-around"
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center p-3"
      >
        <user-avatar :user="{ ...user }" size="lg" />
        <h2 v-if="user && user.name" class="user-name">
          {{ user.name || "" }}
        </h2>
        <p class="text-center">
          Pronta para a jornada de<br class="d-lg-none" />
          fortalecimento
        </p>
      </div>
      <div class="divider"></div>
      <div class="panorama-wrapper p-lg-3">
        <h5 class="panorama-title px-2 px-lg-0 text-center text-lg-start">
          Panorama da sua jornada de {{ user_time }} dias
        </h5>
        <p class="panorama-description small mb-4 px-2 px-lg-0 text-lg-start">
          Aqui está uma visão geral de sua jornada de fortalecimento
        </p>
        <div class="d-none d-lg-flex justify-content-center align-items-center">
          <div class="grey-box left">
            <h2>{{ statistics.watched_lessons_lives || 0 }}</h2>
            <p v-html="text_lesson_live"></p>
          </div>
          <div class="grey-box d-none">
            <h2>{{ statistics.watched_series || 0 }}</h2>
            <p v-html="text_serie"></p>
          </div>
          <div class="grey-box">
            <h2>{{ statistics.watched_challenges || 0 }}</h2>
            <p v-html="text_challange"></p>
          </div>
          <div class="grey-box right">
            <h2>{{ statistics.read_copybooks || 0 }}</h2>
            <p v-html="text_copybooks"></p>
          </div>
        </div>
        <!-- mobile area -->
        <div class="d-lg-none d-flex align-items-center justify-content-center">
          <div class="grey-box left">
            <h2>{{ statistics.watched_lessons_lives || 0 }}</h2>
            <p v-html="text_lesson_live"></p>
          </div>
          <div class="grey-box right">
            <h2>{{ statistics.watched_challenges || 0 }}</h2>
            <p v-html="text_challange"></p>
          </div>
          <div class="grey-box right d-none">
            <h2>{{ statistics.watched_series || 0 }}</h2>
            <p v-html="text_serie"></p>
          </div>
        </div>
        <div class="d-lg-none d-flex align-items-center justify-content-center">
          <div
            class="grey-box"
            style="border-radius: 0 0 10px 10px; width: 260px"
          >
            <h2>{{ statistics.read_copybooks || 0 }}</h2>
            <p v-html="text_copybooks"></p>
          </div>
        </div>
        <!-- mobile area -->
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center align-items-lg-end pt-4 pt-lg-0"
      >
        <router-link
          class="text-primary fw-medium mb-3 mb-lg-5 d-none d-lg-block"
          :to="{ name: 'profile-journey' }"
        >
          Ver <span class="d-mid-lg-none">minha</span> jornada
        </router-link>
        <div class="green-box mb-lg-0 mb-4">
          <h2>{{ statistics.strengthening_sessions || 0 }}</h2>
          <router-link :to="{ name: 'my-sessions' }"
            ><p v-html="text_sessions" class="text-underline"></p
          ></router-link>
        </div>
        <router-link
          class="text-primary fw-medium mb-3 mb-lg-5 d-lg-none"
          :to="{ name: 'profile-journey' }"
        >
          Ver minha jornada
        </router-link>
      </div>
    </div>
  </material-card>
</template>

<script>
import { get_time_diff_duration } from "@/functions/helpers";
import UserAvatar from "@/components/UserAvatar";
import { mapState } from "vuex";
export default {
  components: { UserAvatar },
  data: () => ({ loading: false }),
  mounted() {
    if (this.user) {
      this.$store.dispatch("statistics/get", this.user.id).then();
    } else {
      this.loading = true;
      this.$store
        .dispatch("user/get")
        .then(() => {
          this.$store.dispatch("statistics/get", this.user.id).then();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("statistics", ["statistics"]),
    user_time() {
      if (!this.user) return 0;

      return get_time_diff_duration(this.user.created_at, new Date().toString())
        .asDays()
        .toFixed(0);
    },
    text_lesson_live() {
      if (
        this.statistics.watched_lessons_lives &&
        this.statistics.watched_lessons_lives > 1
      ) {
        return "Aulas e lives<br class='d-none d-lg-block' /> assistidas";
      }
      if (!this.statistics.watched_lessons_lives) {
        return "Aulas e lives<br class='d-none d-lg-block' /> assistidas";
      } else {
        return "Aula ou live <br class='d-none d-lg-block'/> assistida";
      }
    },
    text_serie() {
      if (
        this.statistics.watched_series &&
        this.statistics.watched_series > 1
      ) {
        return "Séries<br class='d-none d-lg-block' /> finalizadas";
      }
      if (!this.statistics.watched_series) {
        return "Séries<br class='d-none d-lg-block' /> finalizadas";
      } else {
        return "Série<br class='d-none d-lg-block' /> finalizada ";
      }
    },
    text_challange() {
      if (
        this.statistics.watched_challenges &&
        this.statistics.watched_challenges > 1
      ) {
        return " Desafios<br class='d-none d-lg-block' /> cumpridos";
      }
      if (!this.statistics.watched_challenges) {
        return " Desafios<br class='d-none d-lg-block' /> cumpridos";
      } else {
        return "Desafio<br class='d-none d-lg-block' /> cumprido";
      }
    },
    text_copybooks() {
      if (
        this.statistics.read_copybooks &&
        this.statistics.read_copybooks > 1
      ) {
        return "Cadernos de<br class='d-none d-lg-block' /> ativação lidos";
      }
      if (!this.statistics.read_copybooks) {
        return "Cadernos de<br class='d-none d-lg-block' /> ativação lidos";
      } else {
        return "Caderno de<br class='d-none d-lg-block' /> ativação lido";
      }
    },
    text_sessions() {
      if (
        this.statistics.strengthening_sessions &&
        this.statistics.strengthening_sessions > 1
      ) {
        return 'Sessões de<br class="d-none d-lg-block" /> fortalecimento';
      }
      if (!this.statistics.strengthening_sessions) {
        return 'Sessões de<br class="d-none d-lg-block" /> fortalecimento';
      } else {
        return 'Sessão de<br class="d-none d-lg-block" /> fortalecimento';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.components-info-box {
  .divider {
    height: 240px;
    width: 1px;
    background: #e9e8fb;

    @media screen and (max-width: 991px) {
      height: 1px;
      width: 240px;
      margin-bottom: 1rem;
    }
  }

  .user-name {
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #33333a;
  }

  .panorama-title {
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #9a99ae;
  }

  .panorama-description {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.4px;
    color: #9a99ae;
  }

  .d-mid-lg-none {
    @media screen and (min-width: 992px) and (max-width: 1040px) {
      display: none;
    }
  }

  .green-box,
  .grey-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 8px;
    background: #f0eeff;
    border: 1px solid #d4d3ea;
    width: 130px;
    height: 108px;

    @media screen and (min-width: 992px) and (max-width: 1040px) {
      width: 119px;
      height: 120px;
    }

    h2 {
      font-family: "Barlow", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 2.6rem;
      color: #5a5966;
      margin-bottom: 0;
    }

    p {
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0.001em;
      color: #7d7c8d;
    }

    &.left {
      border-radius: 12px 0 0 12px;
      @media screen and (max-width: 991px) {
        border-radius: 12px 0 0 0px;
      }
    }

    &.right {
      border-radius: 0 12px 12px 0;
      @media screen and (max-width: 991px) {
        border-radius: 0 12px 0 0;
      }
    }
  }
  .bottom-left {
    @media screen and (max-width: 991px) {
      border-radius: 0px 0 0 12px !important ;
    }
  }
  .bottom-right {
    @media screen and (max-width: 991px) {
      border-radius: 0 0 12px 0 !important;
    }
  }

  .green-box {
    background: #c6fbd3;
    border: 1px solid #d4d3ea;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 8px;

    h2 {
      color: #0e6623;
    }

    p {
      color: #198d34;
      text-decoration-line: underline;
    }
    @media screen and (max-width: 991px) {
      width: 120%;
    }
  }
}
</style>
