<template>
  <router-link
    class="lesson-item mb-3 me-3"
    :to="{
      name: route_name,
      params: route_params,
    }"
    :style="backgroundThumb"
  >
    <div
      class="description d-flex gradient align-items-start justify-content-center px-3"
    >
      <p class="me-2">{{ item.number }}</p>
      <p>{{ item.name }}</p>
    </div>
  </router-link>
</template>
<script>
import get from "lodash/get";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    backgroundThumb() {
      return `background-image: url(${
        this.item.thumb || ""
      }), linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);`;
    },
    route_name() {
      if (!this.item.module && !this.item.formation) {
        return "start-here";
      }

      return "lesson";
    },
    route_params() {
      const module_id = get(this.item, "module.id", this.item.module);
      if (module_id) {
        return {
          module_id,
          id: this.item.id,
        };
      }
      return {
        id: this.item.id,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.lesson-item {
  background-color: #6c6c6c;
  width: 194.67px;
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #fff;
  line-height: 14px;
  @media screen and (max-width: 991px) {
    width: 100% !important;
    min-height: 215px;
  }

  p {
    color: #fff;
    font-size: 12px;
  }
}
.gradient {
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
}

.description {
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
