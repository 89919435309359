<template>
  <div class="components-home-card-item d-flex flex-column m-1" :style="styles">
    <div :class="`card-img ${show_lock ? 'lock' : ''}`" :style="imgBackground">
      <i v-if="show_lock" class="icon-lock"></i>
      <div class="progress-wrapper">
        <div class="progressbar" :style="progress"></div>
      </div>
    </div>
    <div
      v-if="item.description && item.description.length"
      class="card-description flex-fill pb-4 p-3"
      v-html="description_html"
    ></div>

    <div v-if="show_formation_offer" class="purchase-formation-warn p-2">
      <p class="mb-0 pb-0">
        Este curso faz parte da
        <router-link
          :to="{ name: 'formation', params: { id: item.formation.id } }"
          class="link-formation fw-bolder"
        >
          <u>Formação {{ item.formation.title }}</u> </router-link
        >, que você ainda não possui.
      </p>
    </div>

    <div v-if="show_offer" class="d-flex justify-content-center pb-3 px-3">
      <button class="btn btn-primary px-5" @click="openLP">Comprar</button>
    </div>
    <live-lesson-message
      v-if="!show_lock"
      class="pb-3 px-3"
      param="module.course"
      :content="item"
    />
  </div>
</template>
<script>
import LiveLessonMessage from "@/components/LiveLessonMessage";
import { removeTimeZone } from "@/functions/helpers";
import { SalesMixin } from "@/functions/mixins";
import moment from "moment";
export default {
  mixins: [SalesMixin],
  components: { LiveLessonMessage },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openLP() {
      let url = "";
      if (this.item?.product_url) {
        url = this.item.product_url;
      } else if (this.item.product.length) {
        const { email, name, last_name } = this.$store.state.user.user;
        const complete_name = name + " " + last_name;
        const { product, offer } = this.item;
        const past_offer = offer.length ? `offer=${offer[0]}` : "";

        url = `https://pay.blitzpay.com.br/${product[0]}?${past_offer}&email=${email}&confirm_email=${email}&name=${complete_name}`;
      }
      if (url.length) window.open(url, "_blank");
    },
  },
  computed: {
    description_html() {
      return this.item.description;
    },
    show_lock() {
      return (
        !this.check_course_purchase(this.item.id) &&
        !this.check_formation_purchase(this.item.formation.id)
      );
    },
    show_formation_offer() {
      if (this.$route.name == "formation" || !this.item || !this.item.formation)
        return false;

      if (typeof this.item.formation == "number") return false;

      const has_product_purchase_info =
        this.item?.formation?.product.length ||
        this.item?.formation?.product_url;

      return (
        this.check_course_purchase(this.item.id) &&
        has_product_purchase_info &&
        !this.check_formation_purchase(this.item.formation.id)
      );
    },
    show_offer() {
      const has_product_purchase_info = this.item?.product_url;
      return has_product_purchase_info && this.show_lock;
    },
    imgBackground() {
      const lock_filter = this.show_lock
        ? "linear-gradient(90deg, rgba(0,0,0, 0.5) 100%, rgba(0,0,0, 0.5) 100%),"
        : "";
      const img_url = this.item.photo || this.item.cover || "";

      return `background-image: ${lock_filter} url("${img_url}");`;
    },
    styles() {
      if (this.$route.query?.search) return `width: 346px !important;`;

      return "";
    },
    date_info() {
      return moment(removeTimeZone(this.item.created_at)).format(
        "DD[/]MMM[,] HH:mm"
      );
    },
    progress() {
      const style = {
        width: `${this.item.progress || 0}%`,
      };

      if (this.item.progress == 100) {
        style["background-color"] = "#54A3DB";
      } else if (this.item.progress > 50) {
        style["background-color"] = "#55DB74";
      } else {
        style["background-color"] = "#AEA028";
      }

      return style;
    },
  },
};
</script>
