<template>
  <default-template class="settings-template">
    <div class="pt-3 pt-lg-5 px-lg-5 mx-lg-5 py-5">
      <div class="ps-lg-4 py-lg-5 px-lg-5 mt-5">
        <div class="row w-100 m-0">
          <div class="col-lg-9 p-0">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </default-template>
</template>

<script>
import DefaultTemplate from "./DefaultTemplate.vue";

export default {
  components: {
    DefaultTemplate,
  },
};
</script>
<style lang="scss" scoped>
.settings-template {
  background: #e9e8fb;
  @media screen and (max-width: 991px) {
    background: #fff;
    overflow-x: hidden;
  }
}
</style>
