<template>
  <div
    class="form-group mb-0"
    :class="{
      'py-2': !noPadding,
      'has-icon': !!icon,
      'has-value': !!modelValue,
      'is-date': isDate,
    }"
  >
    <div v-if="isDate" class="date-picker-wrapper d-flex">
      <el-date-picker
        v-model="date"
        type="date"
        placeholder="Selecione uma data"
        :shortcuts="dateShortcuts"
        :prefix-icon="customDatePrefix"
      >
      </el-date-picker>
    </div>
    <material-select
      v-if="select"
      class="w-100"
      :class="classObject"
      :id="id"
      v-model="innerValue"
      :filterable="filterable"
      :clearable="nullable"
      :placeholder="placeholder"
      :disabled="disabled"
      autocomplete="nope"
      :options="options"
      :icon="icon"
      :emitAsObject="emitAsObject"
    />
    <auto-complete-select
      v-else-if="autocomplete"
      class="w-100"
      :class="classObject"
      :id="id"
      v-model="innerValue"
      :filterable="filterable"
      :clearable="nullable"
      :placeholder="placeholder"
      :disabled="disabled"
      autocomplete="nope"
      :options="options"
      :icon="icon"
      :emitAsObject="emitAsObject"
      @change-object="onChangeObject"
    />
    <the-mask
      v-else-if="mask.length"
      :mask="mask"
      :masked="masked"
      v-model="innerValue"
      class="form-control"
      @keyup="keyMonitor"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />
    <vue-tel-input
      v-else-if="isPhone"
      v-model="innerValue"
      class="form-control"
      @keyup="keyMonitor"
      :class="classObject"
      :id="id"
      type="tel"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      @country-changed="countryChanged"
      @validate="(obj) => $emit('validate', obj)"
    />
    <MDBTextarea
      v-else-if="textarea"
      :label="label"
      :size="size"
      v-model="innerValue"
      class="form-control"
      @keyup="keyMonitor"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div class="form-password-wrapper" v-else-if="type === 'password'">
      <MDBInput
        v-model="innerValue"
        class="form-control"
        :class="classObject"
        @keyup="keyMonitor"
        :label="label"
        :size="size"
        :id="id"
        :type="!showPassword && type"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : ''"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
      >
        <button
          type="button"
          class="btn clear-button icon-outline-eye"
          @click="showPassword = !showPassword"
        >
          <i :class="`fas fa-${!showPassword ? 'eye' : 'eye-slash'}`"></i>
        </button>
      </MDBInput>
    </div>

    <div class="radio-group text-dark pt-2" v-else-if="type === 'radio'">
      <MDBRadio
        v-for="option in options"
        :key="option.name"
        :label="label"
        :size="size"
        :name="option.name"
        :id="option.name"
        type="radio"
        :value="option.name"
        v-model="innerValue"
      />
    </div>

    <money
      v-else-if="isMoney"
      v-model="innerValue"
      class="form-control"
      v-bind="moneyParams"
      @keyup="keyMonitor"
      :class="classObject"
      :id="id"
      :type="type"
      :maxLength="maxLength"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />

    <MDBInput
      v-else
      v-model="innerValue"
      class="form-control"
      @focusin="setFocus(true)"
      @focusout="setFocus(false)"
      @keyup="keyMonitor"
      :labelClass="`${icon && !on_focus ? 'ps-4' : ''}`"
      :wrapperClass="icon ? 'bg-white wrapper-material' : ''"
      :class="{ classObject, 'ps-5': !!icon }"
      :id="id"
      :type="type"
      :label="label"
      :size="size"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    >
      <i v-if="icon" class="icon-media" :class="icon" />
      <template v-if="withClearButton && modelValue.length" #prepend>
        <button class="btn btn-clear d-none" @click="clear">
          <i class="icon-outline-close" />
        </button>
      </template>
    </MDBInput>

    <div v-if="showError && showErrorMessage" class="invalid-feedback">
      {{ errors[0] }}
    </div>

    <!-- <button
      v-if="withClearButton && modelValue.length"
      class="clear"
      @click="clear"
    >
      <i class="icon-outline-close"></i>
    </button> -->
  </div>
</template>

<script>
import { MDBInput, MDBRadio, MDBTextarea } from "mdb-vue-ui-kit";
import { shallowRef, h } from "vue";
import { Money } from "v-money";

import AutoCompleteSelect from "@/components/AutoCompleteSelect.vue";

const datePrefix = shallowRef({
  render() {
    return h("i", { class: "icon-calendar" });
  },
});

export default {
  components: { Money, MDBInput, MDBRadio, MDBTextarea, AutoCompleteSelect },
  props: {
    label: {
      type: String,
      default: "",
    },
    isMoney: {
      type: Boolean,
      default: false,
    },
    filterable: Boolean,
    nullable: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Array],
      default: "",
    },
    masked: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionLabelFunction: {
      type: Function,
      default: (item) => item.name,
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "lg",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false,
    },
    disableAutocomplete: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withClearButton: {
      type: Boolean,
      default: false,
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    emitAsObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
      showError: this.errors && this.errors.length,
      innerValue: this.modelValue,
      on_focus: false,
      date: null,
    };
  },
  computed: {
    customDatePrefix() {
      return datePrefix;
    },
    dateShortcuts: () => [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Ontem",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "Há uma semana",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ],
    classObject() {
      const result = {
        "is-invalid": this.showError,
        "login-borders": this.isLogin,
        "normal-borders": !this.isLogin,
        "empty-borders": this.innerValue?.length == 0,
      };
      result[this.customClass] = true;
      return result;
    },
  },
  watch: {
    date(newVal) {
      this.innerValue = newVal
        .toString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    },
    errors(newVal) {
      if (newVal && !!newVal[0]) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    innerValue(newVal) {
      if (newVal != this.modelValue) {
        this.showError = false;
        this.$emit("update:modelValue", newVal);

        if (this.isDate && newVal.length === 10) {
          this.date = new Date(
            newVal.split("/").reverse().join("-") + "T12:00:00"
          );
        }
      }
      if (this.isPhone && newVal.match(/[a-zA-Z]/)) {
        setTimeout(() => {
          this.innerValue = newVal.replace(/[a-zA-Z]/, "");
        }, 2);
      }
    },
    modelValue: {
      handler(newVal) {
        this.$emit("change");
        if (this.innerValue !== newVal) {
          this.innerValue = newVal;
          this.showError = false;
        }
      },
    },
  },
  methods: {
    onChangeObject(value) {
      this.$emit("change-object", value);
    },
    keyMonitor(event) {
      this.$emit("keyup", event);
    },
    setFocus(val) {
      this.on_focus = val;
    },
    countryChanged(obj) {
      this.$emit("country-changed", obj);
      setTimeout(() => {
        this.innerValue = document.querySelector(`#${this.id} input`).value;
      }, 1);
    },
    clear() {
      this.$emit("update:modelValue", "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.form-group {
  position: relative;

  .btn-clear {
    box-shadow: none;
    position: absolute;
    top: -2%;
    right: 16px;
    i {
      font-size: 16px;
    }
    &:hover {
      box-shadow: none;
    }
  }

  ::v-deep .wrapper-material {
    border-radius: 12px !important;
  }

  ::v-deep .form-outline .form-control:focus ~ .form-label {
    color: $primary;
  }

  ::v-deep .form-outline .form-control:focus ~ .form-notch .form-notch-leading {
    border-color: $primary;
    border-radius: $primary;
    box-shadow: -1px 0 0 0 $primary, 0 1px 0 0 $primary, 0 -1px 0 0 $primary;
  }

  ::v-deep .form-outline .form-control:focus ~ .form-notch .form-notch-middle {
    border-color: $primary;
    box-shadow: 0 1px 0 0 $primary;
    border-top: 1px solid rgba(0, 0, 0, 0);
  }

  ::v-deep
    .form-outline
    .form-control:focus
    ~ .form-notch
    .form-notch-trailing {
    border-color: $primary;
    box-shadow: 1px 0 0 0 $primary, 0 -1px 0 0 $primary, 0 1px 0 0 $primary;
  }

  ::v-deep .form-control:focus {
    background-color: transparent;
    & > * {
      border-color: $primary !important;
      border-radius: 12px;
    }
  }

  ::v-deep .form-control.form-control-lg {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  ::v-deep .form-notch-trailing {
    border-radius: 0 12px 12px 0 !important;
  }
  ::v-deep .form-notch-leading {
    border-radius: 12px 0 0 12px !important;
  }
  .form-control.is-invalid {
    background-image: none;
  }

  .icon-media {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 54px;
      left: 4px;
    }

    .form-control {
      padding-right: 32px;
    }
  }

  .empty-borders {
    border: 1px solid transparent !important;
  }

  .border-none {
    border: none !important;

    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .clear {
    position: absolute;
    height: 44px;
    right: 4px;
    top: 30%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }

  &:not(.has-value) {
    select {
      color: #aeb6c4 !important;
    }
  }

  :deep .vti__dropdown-list {
    z-index: 3;
  }

  .date-picker-wrapper {
    position: absolute;
    top: 10%;
    left: 0;
    transform: translate(-10%, 0);
    z-index: 1;

    button {
      height: 54px;
    }
  }

  &.is-date {
    .form-control {
      padding-left: 40px;
    }
  }

  .form-control:disabled {
    /* .form-control[readonly] { */
    background: #f5f5f5;
  }

  .form-password-wrapper {
    .icon-outline-eye {
      position: absolute;
      right: 30px;
      left: initial;
      top: 50%;
      transform: translateY(-50%);
      font-size: 17px;
      cursor: pointer;
      user-select: none;
      color: #5a5966;
      box-shadow: none;
    }
    .clear-button {
      background-color: transparent;
      padding: 0px;
      margin: 0px;
      border: 0px;
    }
  }

  .radio-group {
    font-size: 16px;

    input[type="radio"] {
      margin-right: 5px;
    }
  }

  .normal-borders {
    border: 1px solid #cfcfcf;
    border-radius: 12px;
  }

  .login-borders {
    border: 1px solid #595959;
    border-radius: 12px;
  }

  .form-outline,
  .form-control {
    background: #f0eeff;
  }

  ::v-deep .form-control.is-invalid {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }
}
</style>
