<template>
  <div class="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
    <button
      v-for="{ name, slug } in tabs"
      :key="slug"
      @click="inner_active_tab = slug"
      class="btn tab-option d-none d-lg-block"
      :class="{
        'border-primary text-primary btn-no-shadows border-width-3':
          active_tab == slug,
      }"
    >
      {{ name }}
    </button>
    <button
      class="btn tab-option btn-no-shadows flex-fill d-none d-lg-block"
      disabled
    ></button>
    <button
      v-if="!hideGrid"
      class="btn btn-no-shadows d-none d-lg-block"
      @click="$emit('change-grid', !isGrid)"
      :disabled="isDisabled"
    >
      <i
        :class="{
          'icon-list-view': !isGrid,
          'icon-grid': isGrid,
        }"
      />
    </button>
    <div class="d-flex align-items-end">
      <form-group
        @keyup="onKeyUpSearch"
        v-model="inner_search"
        placeholder="Procurar"
        icon="icon-search"
        class="flex-fill"
        label="Pesquisar"
        type="search"
        id="search"
        no-padding
      />
    </div>
    <div class="d-flex d-lg-none">
      <select-input
        v-if="isMobile"
        select
        id="tab"
        class="w-100 me-3"
        v-model="inner_active_tab"
        :options="tabs"
        :isOptions="false"
      />
      <button
        v-if="!hideGrid"
        class="btn btn-no-shadows"
        @click="$emit('change-grid', !isGrid)"
        :disabled="isDisabled"
      >
        <i
          :class="{
            'icon-list-view': !isGrid,
            'icon-grid': isGrid,
          }"
        />
      </button>
    </div>
  </div>
</template>
<script>
import SelectInput from "@/components/SelectInput.vue";

export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    active_tab: {
      type: String,
      default: "courses",
    },
    search: {
      type: String,
      default: "",
    },
    isGrid: {
      type: Boolean,
      default: false,
    },
    hideGrid: {
      type: Boolean,
      default: false,
    },
  },
  components: { SelectInput },
  data() {
    return {
      inner_active_tab: this.active_tab,
      inner_search: this.search,
    };
  },
  computed: {
    isMobile() {
      if (window.innerWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
    isDisabled() {
      if (this.active_tab == "materials") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    inner_active_tab(v) {
      this.$emit("change-tab", v);
    },
    inner_search(v) {
      this.$emit("change-search", v);
    },
  },
  methods: {
    onKeyUpSearch(event) {
      this.$emit("submit-search", event);
    },
  },
};
</script>
