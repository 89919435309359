import { get_time_diff_duration, isMobile } from "@/functions/helpers";
import { mapState } from "vuex";

export default {
  data() {
    return {
      minutes: null,
      seconds: null,
      isFullScreen: isMobile(),
    };
  },
  computed: {
    ...mapState("sessions", ["my_active_session"]),
    currentDuration() {
      let formatted;
      let hourZ = "";
      let minZ = "";
      let secZ = "";

      if (this.minutes / 60 < 10) {
        hourZ = "0";
      }
      if (this.minutes % 60 < 10) {
        minZ = "0";
      }
      if (this.seconds % 60 < 10) {
        secZ = "0";
      }
      formatted = `${hourZ}${Math.floor(this.minutes / 60)}:${minZ}${Math.floor(
        this.minutes % 60
      )}:${secZ}${Math.floor(this.seconds % 60)}`;
      return formatted;
    },
  },
  methods: {
    loadMinutes() {
      this.minutes = get_time_diff_duration(
        this.my_active_session.created_at,
        new Date().toString()
      )
        .asMinutes()
        .toFixed(0);
    },
  },
};
