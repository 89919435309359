export default {
  setLives(state, lives) {
    state.lives = lives;
  },
  setCount(state, count) {
    state.count = count;
  },
  setExclusiveLives(state, lives) {
    state.exclusiveLives = lives;
  },
};
