import actions from "./actions";

export default {
  namespaced: true,
  state: {
    topic: null,
    topics: [],
    likedTopics: [],
    commentedTopics: [],
    categories: [],
    comment: null,
    comments: [],
    count: 0,
  },
  mutations: {
    setTopic(state, topic) {
      state.topic = topic;
    },
    setTopics(state, topics) {
      state.topics = topics;
    },
    setLikedTopics(state, topics) {
      state.likedTopics = topics;
    },
    setCommentedTopics(state, topics) {
      state.commentedTopics = topics;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    addTopics(state, topics) {
      state.topics = [...state.topics, ...topics];
    },
    setComment(state, comment) {
      state.comment = comment;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    addComments(state, comments) {
      state.comments = [...state.comments, ...comments];
    },
    setCount(state, count) {
      state.count = count;
    },
  },
  actions,
};
