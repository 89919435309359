import { createApp } from "vue";
import VueTelInput from "vue-tel-input";
import VueTheMask from "vue-the-mask";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import components from "./components";
import { message, user } from "./plugins/";

import locale from "element-plus/lib/locale/lang/pt-br";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import "mdb-vue-ui-kit/css/mdb.min.css";

import { init } from "./setup/api";
import "./assets/icomoon/style.css";
import "./assets/scss/app.scss";
import "moment/locale/pt-br";

init();

const app = createApp(App);

app.use(message);
app.use(user);
app.use(store);
app.use(ElementPlus, { locale });
app.use(router);
app.use(VueTheMask);
app.use(VueTelInput);

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    app,
    dsn: "https://32d21a0186924181acc1463a735b0519@o1383470.ingest.sentry.io/6700195",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "https://app.mulheresdeforca.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.mount("#app");

for (var i in components) {
  const { name, component } = components[i];
  app.component(name, component);
}
