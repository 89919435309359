<template>
  <div class="components-material-select" v-click-outside="onClickOutside">
    <button
      type="button"
      class="btn btn-form-control w-100"
      :class="{
        'placeholder-color': value_or_placeholder == placeholder,
      }"
      @click="show = true"
    >
      <i v-if="icon.length" :class="`${icon} me-1`"></i>
      {{ value_or_placeholder }}
    </button>
    <div class="suggestions-wrapper p-2" :class="{ show }">
      <button
        v-for="option in options"
        :key="option.id"
        class="btn btn-dropdown-item"
        type="button"
        @click="onSelect(option)"
      >
        {{ option.name }}
      </button>
    </div>
  </div>
</template>
<script>
import vClickOutside from "click-outside-vue3";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: "",
    },
    id: {
      type: String,
      default: "material-select",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    emitAsObject: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "icon-gym-weight",
    },
  },
  data() {
    return {
      innerValue: this.modelValue,
      show: false,
    };
  },
  computed: {
    value_or_placeholder() {
      if (!this.innerValue) return this.placeholder;

      if (typeof this.innerValue == "object") {
        const selected_option = this.options.find(
          (o) => o.id == this.innerValue.id && o.type == this.innerValue.type
        );
        return selected_option.name;
      }
      const selected_option = this.options.find((o) => o.id == this.innerValue);

      return selected_option ? selected_option.name : this.placeholder;
    },
  },
  watch: {
    innerValue(v) {
      this.$emit("update:modelValue", v);
    },
    options() {
      this.innerValue = "";
    },
  },
  methods: {
    clear() {
      this.innerValue = "";
    },
    onSelect(option) {
      if (this.emitAsObject) {
        this.innerValue = option;
      } else {
        this.innerValue = option.id;
      }

      this.show = false;
    },
    onClickOutside() {
      if (this.show) this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.components-material-select {
  position: relative;
  width: fit-content;
  height: fit-content;

  .suggestions-wrapper {
    background: #f2fff5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 9999;

    button {
      min-width: 90%;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    position: absolute;
    top: 90%;
    left: 0;
    display: none;
    height: 0;

    &.show {
      display: block;
      height: fit-content;
    }
  }

  .btn-form-control {
    background: #f0eeff;
    border-radius: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
    position: relative;
    margin: 0;

    letter-spacing: 0.0025em;
    font-style: normal;
    font-weight: 400;
    color: #5a5966;
    font-size: 14px;

    justify-content: flex-start;

    i {
      color: #5a5966;
      font-size: 14px;
    }

    &::after {
      content: " ";
      display: inline-block;
      position: absolute;
      z-index: 3;
      top: 46%;
      right: 3%;
      width: 0;
      height: 0;
      border-radius: 8px 8px 8px 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #000;
    }
  }

  .btn-dropdown-item {
    font-weight: 400 !important;
    font-size: 14px;
    letter-spacing: 0.0025em;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #33333a;
    text-align: left;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
  }

  .placeholder-color {
    color: #5a5966;
  }
}
</style>
