export default {
  setLessons(state, lessons) {
    state.lessons = lessons;
  },
  setLiveLessons(state, live_lessons) {
    state.live_lessons = live_lessons;
  },
  setStartHereLessons(state, start_here_lessons) {
    state.start_here_lessons = start_here_lessons;
  },
  setCount(state, count) {
    state.count = count;
  },
};
