<template>
  <div>
    <div v-if="!formations.length" class="d-flex justify-content-center my-3">
      <p class="text-secondary">Não encontramos formações</p>
    </div>

    <div class="row mb-4">
      <div
        v-for="(item, index) in formations"
        :key="`course-${index}`"
        class="col-lg-6 col-xl-4 mb-4"
        :class="{
          'col-lg-12 col-xl-12': !isGrid,
        }"
      >
        <div
          class="d-flex my-2"
          :class="{
            'justify-content-xl-end': position(index) == 'end' && isGrid,
            'justify-content-xl-start': position(index) == 'start' && isGrid,
            'justify-content-center': isGrid,
          }"
        >
          <button class="btn p-0 m-0 flex-fill" @click="openFormation(item)">
            <formation-card-item v-if="isGrid" :item="item" />
            <text-item v-else :item="item" type="formation" />
            <hr
              v-if="!isGrid && index < formations.length - 1"
              class="bg-dark"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormationCardItem from "./items/FormationCardItem.vue";
import SalesMixin from "@/functions/mixins/sales";
import { getPosition } from "@/functions/helpers";
import TextItem from "./items/TextItem.vue";
import { mapState } from "vuex";

export default {
  mixins: [SalesMixin],
  components: { TextItem, FormationCardItem },
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("formations", ["formations"]),
  },
  methods: {
    position: (i) => getPosition(i),
    openFormation(item) {
      this.$router.push({ name: "formation", params: { id: item.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.grid-size {
  height: 340px;
  @media screen and (max-width: 991px) {
    height: 150px;
  }
}
.text-size {
  height: 80px;
}
</style>
