<template>
  <div v-if="count" class="component-pagination">
    <template v-if="showFirst">
      <router-link class="btn btn-outline-primary" :to="goToPage(1)"
        >1</router-link
      >
      <span>...</span>
    </template>

    <router-link
      v-for="n in pages"
      :key="n"
      class="btn"
      :class="{
        'btn-outline-primary': n !== currentPage,
        'btn-primary': n === currentPage,
      }"
      :to="goToPage(n)"
    >
      {{ n }}
    </router-link>

    <template v-if="showLast">
      <span>...</span>
      <router-link class="btn btn-outline-primary" :to="goToPage(numberPages)">
        {{ numberPages }}
      </router-link>
    </template>
  </div>
</template>

<script>
import { clearObject } from "@/functions/helpers";
import { first, last } from "lodash";

export default {
  props: {
    modelValue: Object,
    count: Number,
  },
  computed: {
    numberPages() {
      return Math.ceil(this.count / this.modelValue.limit);
    },
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
    back() {
      return this.goToPage(this.currentPage - 1);
    },
    next() {
      return this.goToPage(this.currentPage + 1);
    },
    pages() {
      const pages = [];
      for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
        if (i > 0 && i <= this.numberPages) {
          pages.push(i);
        }
      }
      return pages;
    },
    showFirst() {
      return first(this.pages) !== 1;
    },
    showLast() {
      return last(this.pages) !== this.numberPages;
    },
  },
  methods: {
    goToPage(n) {
      return {
        ...this.$route,
        query: clearObject({
          ...this.$route.query,
          page: n,
        }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.component-pagination {
  display: flex;
  align-items: center;
  margin: 0 -4px;

  a {
    margin: 0 4px;
    padding: 0;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: 0.5;
      filter: grayscale(0.1);
    }
  }
}
</style>
