<template>
  <div v-if="live_lesson" class="d-flex justify-content-lg-center">
    <button class="btn btn-live-msg text-danger" @click="openLiveLesson">
      <i class="icon-bell1 me-1"></i>
      {{ live_lesson_msg }}
    </button>
  </div>
</template>
<script>
import { removeTimeZone } from "@/functions/helpers";
import get from "lodash/get";
import moment from "moment";

export default {
  props: ["param", "content"],
  computed: {
    live_lesson() {
      const { live_lessons } = this.$store.state.lessons;

      if (live_lessons.length) {
        const course_lesson = live_lessons.find(
          (l) => get(l, this.param, undefined) == this.content.id
        );

        return course_lesson;
      }

      return false;
    },
    lessonDate() {
      if (this.live_lesson) {
        return moment(removeTimeZone(this.live_lesson.published_at)).format(
          "DD/MMM[,] HH:mm"
        );
      } else {
        return "";
      }
    },
    live_lesson_msg() {
      return `Encontro ao vivo em ${this.lessonDate}`;
    },
  },
  methods: {
    openLiveLesson() {
      if (this.live_lesson && this.$route.name !== "course") {
        this.$router.push({
          name: "lesson",
          params: {
            module_id: this.live_lesson.module.id,
            id: this.live_lesson.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-live-msg {
  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
}
</style>
