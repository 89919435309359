<template>
  <div
    class="components-checkbox-group d-flex align-items-start"
    @click="onClick"
  >
    <i
      :class="{
        'icon-checkbox-not-checked text-primary': !innerSelected,
        'icon-checkbox-checked text-success': innerSelected,
      }"
    ></i>
    <span class="font-weight-bold">{{ label }}</span>
  </div>
</template>
<script>
export default {
  name: "CustomCheckBox",
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerSelected: this.modelValue,
    };
  },
  watch: {
    innerSelected(v) {
      this.$emit("update:modelValue", v);
    },
    modelValue(v) {
      this.innerSelected = v;
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;

      this.innerSelected = !this.innerSelected;
    },
  },
};
</script>
<style lang="scss" scoped>
.components-checkbox-group {
  cursor: pointer;
  i {
    font-size: 16px;
    margin-right: 8px;
    border-radius: 50%;
  }

  span {
    color: #595463;
  }

  .blocked {
    opacity: 0.5;
  }
}
</style>
