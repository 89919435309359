import { adapt_first_sessions } from "../../functions/helpers";

export default {
  get(context, update_session = null) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/strength")
        .then(({ data: { count, next, previous, results } }) => {
          if (results.length || update_session) {
            context.commit("setActiveSessions", results);
            context.commit("setPagination", { count, next, previous });
          }
          resolve(results);
        })
        .catch(reject);
    });
  },
  start(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/strength/create", data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getMy(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/strength/${id}/user?limit=9999999`)
        .then(function (response) {
          if (response.data?.results.length) {
            const opened = response.data.results.find(
              ({ end_session }) => !end_session
            );
            if (opened) {
              context.commit("setMyActiveSession", opened);
              context.commit("setShowSession", true);
            }
          }
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getMine(context, { id, query, l }) {
    let queryParams = 0;
    if (query) {
      queryParams = `order=desc&query=${query}`;
    } else {
      queryParams = `limit=${l || 10}&order=desc`;
    }
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/strength/${id}/user?${queryParams}`)
        .then(({ data: { count, next, previous, results } }) => {
          /* this function (adapt_first_sessions) change the array to get first sections of the day */
          /* Need to be here for prevent system of maximum call stacked in render */
          context.commit("setMySessions", adapt_first_sessions(results));
          context.commit("setPagination", { count, next, previous });
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  finish(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      const payload_form_data = new FormData();
      payload_form_data.append("end_session", true);
      for (let k in payload) {
        if (k == "file") {
          payload_form_data.append(k, payload[k].file);
          continue;
        }
        payload_form_data.append(k, payload[k]);
      }
      window.axios
        .put(`/strength/${id}`, payload_form_data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          context.commit("setMyActiveSession", null);
          context.commit("setShowSession", false);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  update(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/strength/${id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/strength/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  like(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/strength/${id}/interaction`, { like: true })
        .then(resolve)
        .catch(reject);
    });
  },
  superLike(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/strength/${id}/interaction`, { super_like: true })
        .then(resolve)
        .catch(reject);
    });
  },
};
