<template>
  <div
    :id="id"
    class="components-modal-session model-card p-3"
    :class="{
      minimized: isMinimized,
      'd-none': !modelValue,
    }"
    @mousedown="onDown"
    @touchstart="onDown"
    @touchmove="onMove"
  >
    <div class="d-flex align-items-center mb-lg-4">
      <h2 class="flex-fill" :class="{ minimized: isMinimized }">
        <i :class="`${my_active_session.icon || 'icon-gym-weight'}`"></i> Minha
        sessão
      </h2>
      <div v-if="isMinimized" class="duration-wrapper mt-n2">
        <div class="duration">
          <span class="text-dark me-1">{{ currentDuration }}</span> /
          {{ my_active_session.duration }}
        </div>
      </div>
      <div v-else class="duration-wrapper mt-n2 d-lg-none ms-2">
        <div class="duration">
          <span class="text-dark me-1">{{ currentDuration }}</span> /
          {{ my_active_session.duration }}
        </div>
      </div>
      <button
        class="btn btn-no-shadows text-primary mt-n1"
        @click="onChangeMinimize"
      >
        <i
          :class="{
            'icon-minimize': !isMinimized,
            'icon-maximize': isMinimized,
          }"
        ></i>
      </button>
    </div>
    <div class="description-wrapper p-3 d-none d-lg-block">
      <p>
        {{ my_active_session.title }}
      </p>
      <div v-if="my_active_session?.duration" class="duration-wrapper">
        <div class="duration">
          <span class="text-dark me-1">{{ currentDuration }}</span> /
          {{ my_active_session.duration }}
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between pt-4 mb-4 mb-lg-0"
    >
      <router-link
        v-if="linkedContent"
        class="btn btn-course btn-outline-secondary text-primary px-3 mobile-w100"
        :to="{
          name: linkedContent.router_name,
          params: { id: linkedContent.id },
        }"
      >
        <i class="icon-link me-2"></i> {{ linkedContent.title }}
      </router-link>
      <button class="btn btn-danger px-lg-3" @click="openStep1">
        <i class="icon-close me-lg-2"></i>
        <span class="d-none d-lg-block">Encerrar sessão</span>
      </button>
    </div>
  </div>
</template>
<script>
import { get_time_diff_duration, isMobile } from "@/functions/helpers";
import SessionTimeMixin from "@/functions/mixins/sessionTime.js";
import { mapState } from "vuex";

export default {
  mixins: [SessionTimeMixin],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "modal-session",
    },
  },
  data() {
    return {
      open_finish_step_1: false,
      open_finish_step_2: false,
      isMinimized: true,
      elementDrag: null,
      started_at: null,
      timeout: null,
      prevTouch: null,
    };
  },
  mounted() {
    this.loadMinutes();

    this.timeout = setInterval(() => {
      this.minutes = get_time_diff_duration(
        this.my_active_session.created_at,
        new Date().toString()
      ).asMinutes();
      this.seconds = get_time_diff_duration(
        this.my_active_session.created_at,
        new Date().toString()
      ).asSeconds();
    }, 1000);
  },
  unmounted() {
    clearInterval(this.timeout);
  },
  computed: {
    linkedContent() {
      if (this.my_active_session.serie)
        return {
          ...this.my_active_session.serie,
          router_name: "serie",
        };

      if (this.my_active_session.challenge)
        return {
          ...this.my_active_session.challenge,
          router_name: "challenge",
        };

      return null;
    },
  },
  methods: {
    onChangeMinimize(event) {
      event.stopPropagation();
      event.preventDefault();

      this.isMinimized = !this.isMinimized;
    },
    openStep1() {
      this.onUp();
      this.$emit("open-finish-1", true);
    },
    onDown() {
      this.elementDrag = document.getElementById(this.id);
      if (isMobile()) {
        document.ontouchend = this.onUp;
      } else {
        this.elementDrag.onmousemove = this.onMove;
        document.onmouseup = this.onUp;
      }
    },
    onUp() {
      this.elementDrag = null;
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchend = null;
      this.prevTouch = null;
    },
    fixMobileMovement(event) {
      const new_event = { ...event };
      if (this.prevTouch) {
        const { screenX, screenY } = event.touches[0];
        new_event.movementX = screenX - this.prevTouch.screenX;
        new_event.movementY = screenY - this.prevTouch.screenY;
      }
      this.prevTouch = event.touches[0];
      return new_event;
    },
    onMove(event) {
      if (this.elementDrag) {
        const { left, top } = window.getComputedStyle(this.elementDrag);
        const target = isMobile() ? this.fixMobileMovement(event) : event;
        const { movementX, movementY } = target;
        this.elementDrag.style.left = `${parseInt(left) + movementX}px`;
        this.elementDrag.style.top = `${parseInt(top) + movementY}px`;
      }
      if (isMobile()) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.components-modal-session {
  background-color: #fcfcfc;
  width: 480px;
  height: 258px;
  z-index: 199999;
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow-y: hidden;
  position: fixed;
  top: 40%;
  left: 5%;
  cursor: pointer;
  user-select: none;

  & > * {
    user-select: none;
  }

  &.minimized {
    width: 320px;
    height: 56px;
  }

  @media screen and (max-width: 768px) {
    width: 304px;
    max-height: 136px;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0015em;
    color: #33333a;
    &.minimized {
      font-size: 16px;
      letter-spacing: 0.001em;
      color: #5a5966;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
      letter-spacing: 0.001em;
    }
  }

  .description-wrapper {
    background: #f0eeff;
    border-radius: 12px;
    padding: 16px 15px;
    p {
      color: #5a5966;
    }
  }

  .duration-wrapper {
    background: linear-gradient(169.12deg, #28ae47 30.87%, #d4d3ea 56.85%);
    border-radius: 8px;
    width: fit-content;
    padding: 1px;
    .duration {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fcfcfc;
      color: #5a5966;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      width: 105px;
      height: 26px;
    }
  }
  .btn-danger {
    i {
      font-size: 10px;
    }
  }

  .btn-course {
    i {
      font-size: 12px;
    }
  }
  .btn {
    @media screen and (max-width: 991px) {
      height: 36px;
    }
  }
}
</style>
