<template>
  <div class="components-home-card-item d-flex flex-column m-1" :style="styles">
    <div
      :class="`card-img ${is_not_purchased ? 'lock' : ''}`"
      :style="imgBackground"
    >
      <i v-if="is_not_purchased" class="icon-lock"></i>
      <div class="progress-wrapper">
        <div class="progressbar" :style="get_progress(item.progress)"></div>
      </div>
    </div>
    <div
      v-if="item.description && item.description.length"
      class="card-description flex-fill p-3 pb-4"
      v-html="description_html"
    ></div>

    <div
      v-if="is_not_purchased"
      class="d-flex justify-content-between pb-3 px-3"
    >
      <div
        class="d-grid w-50"
        :class="{
          'w-100': !has_sales_info,
        }"
      >
        <router-link
          class="btn text-primary btn-block"
          :to="{ name: 'formation', params: { id: item.id } }"
        >
          Ver conteúdo
        </router-link>
      </div>
      <div v-if="has_sales_info" class="d-grid w-50">
        <button class="btn btn-primary btn-block" @click.stop="openLP">
          Comprar
        </button>
      </div>
    </div>
    <live-lesson-message
      v-if="!is_not_purchased"
      class="pb-3 px-3"
      param="module.formation"
      :content="item"
    />
  </div>
</template>
<script>
import LiveLessonMessage from "@/components/LiveLessonMessage";
import { removeTimeZone } from "@/functions/helpers";
import { SalesMixin } from "@/functions/mixins";
import moment from "moment";
export default {
  mixins: [SalesMixin],
  components: { LiveLessonMessage },
  props: {
    type: String,
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openLP() {
      let url = "";

      if (this.item?.product_url) {
        url = this.item.product_url;
      } else if (this.item.product.length) {
        const { email, name, last_name } = this.$store.state.user.user;
        const { product } = this.item;
        url = `https://pay.blitzpay.com.br/${
          product[0]
        }?email=${email}&confirm_email=${email}&name=${name + " " + last_name}`;
      }

      if (url.length) window.open(url, "_blank");
    },
    get_progress(progress) {
      const style = {
        width: `${progress || 0}%`,
      };

      if (progress == 100) {
        style["background-color"] = "#54A3DB";
      } else if (progress > 50) {
        style["background-color"] = "#55DB74";
      } else {
        style["background-color"] = "#AEA028";
      }

      return style;
    },
  },
  computed: {
    has_sales_info() {
      return this.item?.product_url;
    },
    description_html() {
      if (this.item.description.length > 270) {
        return `${this.item.description.slice(0, 270)}...`;
      }
      return this.item.description;
    },
    is_not_purchased() {
      if (!this.item) return true;

      return !this.check_formation_purchase(this.item.id);
    },
    imgBackground() {
      const lock_filter = this.is_not_purchased
        ? "linear-gradient(90deg, rgba(0,0,0, 0.5) 100%, rgba(0,0,0, 0.5) 100%),"
        : "";

      return `background-image: ${lock_filter} url("${
        this.item.photo || this.item.cover || ""
      }");`;
    },
    styles() {
      if (this.$route.query?.search) return `width: 346px !important;`;

      return "";
    },
    date_info() {
      return moment(removeTimeZone(this.item.created_at)).format(
        "DD[/]MMM[,] HH:mm"
      );
    },
    color() {
      let color = "#AEA028";
      switch (true) {
        case this.item.progress == 0 || this.item.progress > 0:
          color = "#73bbea";
          break;
        case this.item.progress == 100:
          color = "#55DB74";
          break;
      }
      return color;
    },
  },
};
</script>
