<template>
  <material-modal v-model="innerValue" :size="size" :fullscreen="isFullScreen">
    <finish-step-1
      v-if="step == 1"
      :session="my_active_session"
      @open-step-2="openStep2"
    />

    <finish-step-2
      v-if="step == 2"
      :session="my_active_session"
      @return="step = 1"
      @close="innerValue = false"
    />
  </material-modal>
</template>
<script>
import FinishStep1 from "@/components/strength-session/finish/FinishStep1.vue";
import FinishStep2 from "@/components/strength-session/finish/FinishStep2.vue";
import { isMobile } from "@/functions/helpers";
import { mapState } from "vuex";

export default {
  components: { FinishStep1, FinishStep2 },
  computed: {
    ...mapState("sessions", ["my_active_session"]),
  },
  props: ["modelValue"],
  data() {
    return {
      step: 1,
      innerValue: this.modelValue,
      isFullScreen: isMobile(),
      size: "md",
    };
  },
  watch: {
    modelValue(v) {
      this.innerValue = v;
    },
    innerValue(v) {
      this.$emit("update:modelValue", v);
    },
  },
  methods: {
    openStep2() {
      this.step = 2;
      this.size = "lg";
    },
  },
};
</script>
