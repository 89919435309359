export default {
  get(context, params = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(params).toString();
      window.axios
        .get(`/formation?${query_params}`)
        .then(({ data: { count, total, previous, next, results } }) => {
          context.commit("setFormations", results);
          context.commit("setCount", count);
          resolve({ count, total, previous, next, results });
        })
        .catch(reject);
    });
  },
  getFormation(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/formation/${id}`)
        .then((response) => {
          context.commit("setFormation", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
