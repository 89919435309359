<template>
  <div class="components-home-card-item d-flex flex-column m-1" :style="styles">
    <div :class="`card-img`" :style="imgBackground">
      <div class="progress-wrapper">
        <div class="progressbar" :style="get_progress(item.progress)"></div>
      </div>
    </div>
    <div
      v-if="item.description && item.description.length"
      class="card-description flex-fill p-3 pb-4"
      v-html="description_html"
    ></div>
  </div>
</template>
<script>
import { removeTimeZone } from "@/functions/helpers";
import SalesMixin from "@/functions/mixins/sales";
import moment from "moment";
export default {
  mixins: [SalesMixin],
  props: {
    type: String,
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    get_progress(progress) {
      const style = {
        width: `${progress || 0}%`,
      };

      if (progress == 100) {
        style["background-color"] = "#54A3DB";
      } else if (progress > 50) {
        style["background-color"] = "#55DB74";
      } else {
        style["background-color"] = "#AEA028";
      }

      return style;
    },
  },
  computed: {
    description_html() {
      if (this.item.description.length > 270) {
        return `${this.item.description.slice(0, 270)}...`;
      }
      return this.item.description;
    },
    imgBackground() {
      const lock_filter = this.is_not_purchased
        ? "linear-gradient(90deg, rgba(0,0,0, 0.5) 100%, rgba(0,0,0, 0.5) 100%),"
        : "";

      return `background-image: ${lock_filter} url("${
        this.item.photo || this.item.cover || ""
      }");`;
    },
    styles() {
      if (this.$route.query?.search) return `width: 346px !important;`;

      return "";
    },
    date_info() {
      return moment(removeTimeZone(this.item.created_at)).format(
        "DD[/]MMM[,] HH:mm"
      );
    },
    color() {
      let color = "#AEA028";
      switch (true) {
        case this.item.progress == 0 || this.item.progress > 0:
          color = "#73bbea";
          break;
        case this.item.progress == 100:
          color = "#55DB74";
          break;
      }
      return color;
    },
  },
};
</script>
