<template>
  <div class="views-home">
    <cover-area :cover="userCover" />
    <section class="content-area py-5">
      <div class="container mx-lg-auto">
        <info-box class="mt-lg-negative" />

        <notification-warn />

        <content-area />
      </div>
    </section>
  </div>
</template>

<script>
import NotificationWarn from "@/components/NotificationWarn.vue";
import ContentArea from "@/components/home/ContentArea.vue";
import InfoBox from "@/components/home/InfoBox.vue";

export default {
  components: { InfoBox, NotificationWarn, ContentArea },
  computed: {
    userCover() {
      if (this.$store.state.user.user && this.$store.state.user.user.cover)
        return this.$store.state.user.user.cover;

      return "";
    },
  },
  mounted() {
    this.$store.dispatch("lessons/getLiveLesson").then();
  },
};
</script>

<style lang="scss" scoped>
.views-home {
}
</style>
