<template>
  <div>
    <h4 class="mb-3">Formações</h4>
    <div v-if="formations.length" class="d-flex flex-wrap mb-5">
      <button
        v-for="(item, i) in formations"
        :key="`course-${i}`"
        :to="{ name: 'course', params: { id: item.id } }"
        class="btn p-0 m-0 mb-3"
        @click="() => openFormation(item)"
      >
        <formation-card-item v-if="isGrid" :item="item" />
        <text-item v-else :item="item" />
        <hr v-if="!isGrid && i < formations.length - 1" class="bg-dark" />
      </button>
    </div>
    <p v-else class="mb-5">
      Não foram encontrados formações para: {{ $route.query.search }}
    </p>

    <h4 class="mb-3">Cursos</h4>
    <div v-if="courses.length" class="d-flex flex-wrap mb-5">
      <button
        v-for="(item, i) in courses"
        :key="`course-${i}`"
        @click="() => openCourse(item)"
        class="btn m-0 p-0 mb-3"
      >
        <course-card-item v-if="isGrid" :item="item" />
        <text-item v-else :item="item" />
        <hr v-if="!isGrid && i < courses.length - 1" class="bg-dark" />
      </button>
    </div>
    <p v-else class="mb-5">
      Não foram encontrados cursos para: {{ $route.query.search }}
    </p>

    <h4 v-if="challenges.length" class="mb-3">Desafios</h4>
    <div v-if="challenges.length" class="d-flex flex-wrap mb-5">
      <button
        v-for="(item, i) in challenges"
        :key="`course-${i}`"
        @click="() => openCourse(item)"
        class="btn m-0 p-0 mb-3"
      >
        <course-card-item v-if="isGrid" :item="item" />
        <text-item v-else :item="item" />
        <hr v-if="!isGrid && i < challenges.length - 1" class="bg-dark" />
      </button>
    </div>
    <p v-else class="mb-5">
      Não foram encontrados desafios para: {{ $route.query.search }}
    </p>

    <h4 class="mb-3">Aulas</h4>
    <div v-if="lessons.length" class="d-flex flex-wrap align-items-center mb-5">
      <lesson-item
        v-for="(item, i) in lessons"
        :key="`lesson-${i}`"
        :item="item"
      />
    </div>
    <p v-else class="mb-5">
      Não foram encontradas aulas para: {{ $route.query.search }}
    </p>

    <h4 class="mb-3">Lives</h4>
    <div v-if="lives.length" class="d-flex flex-wrap align-items-center mb-5">
      <button
        v-for="(item, i) in live_list"
        :key="`course-${i}`"
        class="btn m-0 p-0"
        :class="{ 'ms-0': i == 0 }"
        @click="openLive(item)"
      >
        <live-item :item="item" />
      </button>
    </div>
    <p v-else class="mb-5">
      Não foram encontradas lives para: {{ $route.query.search }}
    </p>

    <h4 class="mb-3">Materiais</h4>

    <div v-if="materials.length" class="row">
      <div
        v-for="(item, i) in materials"
        :key="`material${i}`"
        class="col-xl-2 col-lg-3 col-md-4 col-6 py-2"
      >
        <material-item :item="item" />
      </div>
    </div>

    <p v-else>
      Não foram encontrados materiais para: {{ $route.query.search }}
    </p>
  </div>
</template>
<script>
import FormationCardItem from "./items/FormationCardItem.vue";
import CourseCardItem from "./items/CourseCardItem.vue";
import MaterialItem from "./items/MaterialItem.vue";
import SalesMixin from "@/functions/mixins/sales";
import LessonItem from "./items/LessonItem.vue";
import LiveItem from "./items/LiveItem.vue";
import TextItem from "./items/TextItem.vue";

import { mapState } from "vuex";

export default {
  mixins: [SalesMixin],
  components: {
    TextItem,
    CourseCardItem,
    FormationCardItem,
    MaterialItem,
    LessonItem,
    LiveItem,
  },
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("formations", ["formations"]),
    lives() {
      if (!Object.entries(this.list).length) return [];
      return this.list.lives.results;
    },
    materials() {
      if (!Object.entries(this.list).length) return [];
      return this.list.materials.results;
    },
    lessons() {
      if (!Object.entries(this.list).length) return [];
      return this.list.lessons.results;
    },
    courses() {
      if (!Object.entries(this.list).length) return [];
      return this.list.courses.results.filter(
        ({ type }) => type != "challenge"
      );
    },
    challenges() {
      if (!Object.entries(this.list).length) return [];

      return this.list.courses.results.filter(
        ({ type }) => type == "challenge"
      );
    },
  },
  methods: {
    openFormation(item) {
      this.$router.push({ name: "formation", params: { id: item.id } });
    },
    openCourse(item) {
      if (
        this.check_course_purchase(item.id) ||
        this.check_formation_purchase(item?.formation?.id)
      )
        this.$router.push({ name: "course", params: { id: item.id } });
      else
        this.$message.warning(
          "Você ainda não possui a assinatura deste curso."
        );
    },
    openLive(item, is_exclusive = undefined) {
      if (
        this.check_live_purchase(item.id) ||
        this.check_formation_purchase(item?.formation?.id)
      ) {
        const past_query = is_exclusive ? { query: { exclusive: "true" } } : {};

        this.$router.push({
          name: "live",
          params: { id: item.id },
          ...past_query,
        });
      } else {
        this.$message.warning(
          "Você ainda não possui a assinatura desta live ou da formação que ela pertence."
        );
      }
    },
  },
};
</script>
