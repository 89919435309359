export default {
  setAllTopics(state, all_topics) {
    state.all_topics = all_topics;
  },
  setSessions(state, sessions) {
    state.sessions = sessions;
  },
  setCourse(state, course) {
    state.course = course;
  },
  setLessons(state, lessons) {
    state.lessons = lessons;
  },
  setMaterials(state, materials) {
    state.materials = materials;
  },
  setLives(state, lives) {
    state.lives = lives;
  },
  setCount(state, count) {
    state.count = count;
  },
};
