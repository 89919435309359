<template>
  <aside
    :class="{ open: showMenu, locked: lockedMenu }"
    @mouseenter="onOpen"
    @mouseleave="onClose"
  >
    <div class="aside-content flex-fill pt-5 pb-3 px-2">
      <button
        class="btn btn-custom-close custom-d-lg-none"
        @click="$emit('close')"
      >
        <i class="icon-close"></i>
      </button>
      <div class="brand pt-5 mb-5">
        <button
          class="btn btn-no-shadows option no-hover p-0 m-0"
          @click="$router.push({ name: 'home' })"
        >
          <img
            v-if="showMenu || lockedMenu"
            src="@/assets/images/logo-grey.svg"
            alt="MULHERES DE FORÇA"
            height="32px"
            width="127.37px"
          />
          <img
            v-else
            src="@/assets/images/logo-grey-small.svg"
            alt="MULHERES DE FORÇA"
            height="30.23px"
            width="35.03px"
          />
        </button>
      </div>
      <div class="w-100">
        <router-link
          class="option mb-3"
          :class="{
            'text-center': !showMenu && !lockedMenu,
            'p-2': showMenu || lockedMenu,
          }"
          :to="{ name: 'home' }"
          @click="$emit('set-visibility', false)"
        >
          <i class="icon-chart"></i>
          <span :class="[showMenu ? 'ms-n4' : '']">Painel</span>
        </router-link>

        <router-link
          class="option mb-3"
          :class="{
            'text-center': !showMenu && !lockedMenu,
            'p-2': showMenu || lockedMenu,
          }"
          :to="{ name: 'strength-session' }"
          @click="$emit('set-visibility', false)"
        >
          <i class="icon-gym-weight"></i>
          <span :class="[showMenu ? 'ms-n4' : '']"
            >Sessão de <br />fortalecimento</span
          >
        </router-link>
        <router-link
          class="option mb-3"
          :class="{
            'text-center': !showMenu && !lockedMenu,
            'p-2': showMenu || lockedMenu,
          }"
          :to="{ name: 'my-sessions' }"
          @click="$emit('set-visibility', false)"
        >
          <i class="icon-list"></i>
          <span :class="[showMenu ? 'ms-n4' : '']">Minhas sessões</span>
        </router-link>
        <router-link
          class="option mb-3"
          :class="{
            'text-center': !showMenu && !lockedMenu,
            'p-2': showMenu || lockedMenu,
          }"
          :to="{ name: 'profile-journey' }"
          @click="$emit('set-visibility', false)"
        >
          <i class="icon-heart-chart"></i>
          <span :class="[showMenu ? 'ms-n4' : '']">Perfil e Jornada</span>
        </router-link>
        <router-link
          class="option mb-3"
          :class="{
            'text-center': !showMenu && !lockedMenu,
            'p-2': showMenu || lockedMenu,
          }"
          :to="{ name: 'community' }"
          @click="$emit('set-visibility', false)"
        >
          <i class="icon-users"></i>
          <span :class="[showMenu ? 'ms-n4' : '']">Comunidade MDF</span>
        </router-link>
      </div>
    </div>
    <div class="d-flex flex-column px-2">
      <div class="flex-fill"></div>
      <profile-dropdown class="mb-4" :short="!showMenu && !lockedMenu" />
    </div>
  </aside>
</template>
<script>
import ProfileDropdown from "@/components/ProfileDropdown";

export default {
  components: {
    ProfileDropdown,
  },
  props: {
    lockedMenu: Boolean,
    showMenu: Boolean,
  },
  methods: {
    onOpen() {
      if (window.innerWidth > 1051) {
        this.$emit("open");
      }
    },
    onClose() {
      if (window.innerWidth > 1051) {
        this.$emit("close");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
$sidebar__opened__width: 260px;
$sidebar__closed__width: 60px;

aside {
  position: fixed;
  background: $light;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
  width: $sidebar__closed__width;

  box-shadow: 5px 5px 5px rgb(0 0 0 / 3%);

  @media screen and (max-width: 1050px) {
    width: 0px;
  }

  .aside-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    flex: 1 1 auto;
    background: #fcfcfc;
    position: relative;
    height: 100%;

    .btn-lock {
      position: absolute;
      right: -2%;
      top: 7%;
      border: none;
      border-radius: 24px;
      height: 24px;
      width: 24px;
      background: white;
      display: none;
      z-index: 3;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      justify-content: center;
      align-items: center;
      padding: 0;
      color: rgba(51, 51, 51, 0.6);

      i {
        font-size: 16px !important;
      }

      @media screen and (max-width: 1050px) {
        display: none !important;
      }
    }

    .btn-custom-close {
      position: absolute;
      top: 5%;
      right: -1%;
      color: #c5c4db;
      box-shadow: none;
      i {
        font-size: 13px;
      }
    }

    .brand {
      .option {
        color: #0d0601 !important;
      }
    }
    ::v-deep .option {
      width: 100%;
      display: flex;
      cursor: pointer;
      justify-content: flex-start;
      align-items: center;
      color: #7d7c8d;
      font-weight: bold;

      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.01em;
      font-feature-settings: "pnum" on, "lnum" on;

      i {
        font-size: 24px;
        color: #7d7c8d;
        width: $sidebar__closed__width;
        margin-right: 1rem;
        min-width: 46px;
      }

      img {
        height: 32px;
        width: 127.37px;

        &.small {
          height: 35.03px;
          width: 30.23px;
        }
      }

      &:hover:not(.no-hover) {
        background: #f0eeff;
        border-radius: 8px !important;
      }

      &.router-link-active:not(.home):not(.context-option),
      &.router-link-exact-active {
        font-weight: bold;
        background: #f0eeff;
        border-radius: 8px !important;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      span {
        white-space: nowrap;
        flex: 1 1 auto;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #7d7c8d;
      }
    }
  }

  &.locked {
    @media screen and (min-width: 1050px) {
      width: $sidebar__opened__width;
      padding-right: 20px;

      .btn-lock {
        display: flex;
      }
    }
  }

  &.open {
    width: $sidebar__opened__width;
    padding-right: 20px;

    .btn-lock {
      display: flex;
    }
  }
}
</style>
