import { clearObject } from "@/functions/helpers";
import constants from "@/constants.js";

export default {
  props: {
    formation: {
      type: Object,
      default: null,
    },
  },
  data() {
    let active_tab = "formations";

    if (this.$route.name == "formation") {
      // is in formation detailed screen
      active_tab = "courses";
    }

    if (this.$route.query.tab) {
      active_tab = this.$route.query.tab;
    }

    if (this.$route.query?.search) {
      active_tab = "all";
    }

    return {
      search: this.$route.query.search || "",
      search_results: {},
      loading: false,
      isGrid: true,
      active_tab,
    };
  },
  watch: {
    active_tab(tab) {
      this.$router.push({
        ...this.$route,
        query: clearObject({
          ...this.$route.query,
          tab,
        }),
      });

      if (screen.width < 640 || screen.height < 480) {
        setTimeout(() => {
          window.scroll({ top: 900, left: 0, behavior: "smooth" });
        }, 10);
      }
    },
    search(val) {
      if (!val.length) {
        this.$router.push({
          ...this.$route,
          query: clearObject({
            ...this.$route.query,
            tab: "courses",
            search: "",
          }),
        });
      }
    },
  },
  computed: {
    ACTIONS_MAP: () => ({
      "start-here": "lessons/getStartHere",
      formations: "formations/get",
      all: "user/search_content",
      materials: "materials/get",
      courses: "courses/get",
      lives: "lives/get",
    }),
    count() {
      return {
        lives: this.$store.state.lives.count,
        courses: this.$store.state.courses.count,
        materials: this.$store.state.materials.count,
        "start-here": this.$store.state.lessons.count,
        formations: this.$store.state.formations.count,
      }[this.active_tab];
    },
    params() {
      let payload = {
        ...this.$route.query,
        limit: constants.API.PARAMS.LIMIT,
        offset: 0,
      };

      // is in formation detailed screen
      if (this.$route.name == "formation")
        payload.formation_id = this.$route.params.id;

      if (this.$route.query.search) payload.query = this.$route.query.search;

      if (this.$route.query.page)
        payload.offset = payload.limit * (parseInt(this.$route.query.page) - 1);

      return payload;
    },
    tabs() {
      let tabs = constants.TABS.filter(({ id }) => id !== "start-here");

      if (
        this.$route.name != "formation" &&
        this.$store.state.lessons.start_here_lessons.length > 0
      ) {
        tabs = constants.TABS;
      } else if (this.$route.query.search) {
        tabs = constants.SEARCH_TABS;
      } else if (this.$route.name == "formation") {
        tabs = constants.FORMATION_CONTENT_TABS;
      }

      return tabs;
    },
  },
  methods: {
    onChangeTab(id) {
      this.active_tab = id;
    },
    onChangeSearch(term) {
      this.search = term;
    },
    onChangeGrid(value) {
      this.isGrid = value;
    },
    submitSearch(event) {
      if (event.key === "Enter") {
        this.$router.push({
          ...this.$route,
          query: {
            tab: "all",
            search: this.search,
          },
        });
      }
    },
    refresh() {
      this.loading = true;
      const payload = {};

      ["query", "limit", "offset", "page", "formation_id"].forEach((param) => {
        if (this.params[param]) payload[param] = this.params[param];
      });

      const action = this.ACTIONS_MAP[this.active_tab] || "courses/get";

      this.$store
        .dispatch(action, payload)
        .then((data) => {
          if (this.search) {
            this.search_results = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("formations/get").then();
    this.$store.dispatch("lessons/getStartHere").then();
    this.refresh();
  },
};
