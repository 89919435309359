import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DefaultTemplate from "../templates/DefaultTemplate.vue";
import RightSpacedTemplate from "../templates/RightSpacedTemplate.vue";
import AuthTemplate from "../templates/AuthTemplate.vue";
import BlankTemplate from "../templates/BlankTemplate.vue";
import SettingsTemplate from "../templates/SettingsTemplate.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/",
    name: "account",
    component: HomeView,
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/sessoes-fortalecimento",
    name: "strength-session",
    component: () => import("../views/StrengthSessionView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/minhas-sessoes",
    name: "my-sessions",
    component: () => import("../views/MySessionsView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/perfil-jornada",
    name: "profile-journey",
    component: () => import("../views/ProfileJourneyView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/comunidade",
    name: "community",
    component: () => import("../views/community/IndexView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/comunidade/:id/:slug",
    name: "community-topic",
    component: () => import("../views/community/TopicView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
  {
    path: "/curso/:id",
    name: "course",
    component: () => import("../views/CourseView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
      breadcrumbs: [
        { title: "Painel", route: "home" },
        { title: "Formação", route: "formation", key_params: ["id"] },
        { title: "Curso", route: "" },
      ],
    },
  },
  {
    path: "/material/:id",
    name: "material",
    component: () => import("../views/MaterialView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
      breadcrumbs: [
        { title: "Painel", route: "home" },
        { title: "Formação", route: "formation", key_params: ["id"] },
        { title: "Material", route: "" },
      ],
    },
  },
  {
    path: "/live/:id",
    name: "live",
    component: () => import("../views/LiveView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
      breadcrumbs: [
        { title: "Painel", route: "home" },
        { title: "Formação", route: "formation", key_params: ["id"] },
        { title: "Live", route: "" },
      ],
    },
  },
  {
    path: "/curso/:module_id/aula/:id",
    name: "lesson",
    component: () => import("../views/LessonView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
      breadcrumbs: [
        { title: "Painel", route: "home" },
        { title: "Formação", route: "formation", key_params: ["id"] },
        {
          title: "Curso",
          route: "course",
          key_params: ["id", "module_id"],
        },
        { title: "Aula", route: "" },
      ],
    },
  },
  {
    path: "/minha-conta",
    name: "account",
    component: () => import("../views/AccountInfoView.vue"),
    meta: {
      template: RightSpacedTemplate,
      protected: true,
    },
  },
  {
    path: "/configuracoes",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
    meta: {
      template: SettingsTemplate,
      protected: true,
    },
  },
  {
    path: "/notificacoes",
    name: "notifications",
    component: () => import("../views/NotificationsView.vue"),
    meta: {
      template: RightSpacedTemplate,
      protected: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    meta: {
      template: AuthTemplate,
      protected: false,
    },
  },
  {
    path: "/cadastro",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/RegisterView.vue"),
    meta: {
      template: AuthTemplate,
      protected: false,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFoundView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: false,
    },
  },
  {
    path: "/politicas-de-privacidade",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue"),
    meta: {
      template: BlankTemplate,
      protected: false,
    },
  },
  {
    path: "/termos-de-uso",
    name: "terms",
    component: () => import("../views/TermsView.vue"),
    meta: {
      template: BlankTemplate,
      protected: false,
    },
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/404",
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/ResetPasswordView.vue"),
    meta: {
      template: AuthTemplate,
      protected: false,
    },
  },
  {
    path: "/perguntas-frequentes",
    name: "faq",
    component: () => import("../views/FaqView.vue"),
    meta: {
      template: BlankTemplate,
      protected: false,
    },
  },
  {
    path: "/formacao/:id",
    name: "formation",
    component: () => import("../views/FormationView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
      breadcrumbs: [
        { title: "Painel", route: "home" },
        { title: "Formação", route: "" },
      ],
    },
  },
  {
    path: "/comece-aqui/:id",
    name: "start-here",
    component: () => import("../views/StartHereView.vue"),
    meta: {
      template: DefaultTemplate,
      protected: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
