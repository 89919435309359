<template>
  <footer class="templates-app-footer text-light py-5">
    <div class="container mx-auto">
      <div class="row align-items-start justify-content-center">
        <div class="col-lg-7">
          <img
            src="@/assets/images/logo-grey.svg"
            alt="MULHERES DE FORÇA"
            class="mb-2"
            width="127.37px"
            height="32px"
          />
          <p>© 2022</p>
        </div>
        <div class="col-lg-5">
          <div class="row text-lg-end">
            <div class="col-lg-5 mb-4 mb-lg-0">
              <h4 class="fw-medium">SOCIAL</h4>
              <div class="mb-2">
                <a
                  target="_blank"
                  href="https://instagram.com/pamelaarumaa?igshid=YmMyMTA2M2Y="
                >
                  INSTAGRAM
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://www.youtube.com/c/P%C3%A2melaArumaa"
                >
                  YOUTUBE
                </a>
              </div>
            </div>
            <div class="col-lg-7">
              <h4 class="fw-medium">MAIS INFORMAÇÕES</h4>
              <div class="mb-2">
                <a target="_blank" href="/perguntas-frequentes">
                  PERGUNTAS FREQUENTES
                </a>
              </div>
              <div class="mb-2">
                <a target="_blank" href="termos-de-uso"> TERMOS DE USO </a>
              </div>
              <div class="mb-2">
                <a target="_blank" href="politicas-de-privacidade">
                  POLÍTICA DE PRIVACIDADE
                </a>
              </div>
              <div>
                <a target="_blank" href="mailto:suporte@pamelaarumaa.com">
                  CONTATO
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style lang="scss" scoped>
.templates-app-footer {
  background: #000000;
  padding: 32px 0;

  :deep .hide-footer {
    display: none !important;
  }
  .container {
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }

  h4 {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;

    color: #fcfcfc;
  }

  ::v-deep a,
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #b3b3b3;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
