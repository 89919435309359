export default {
  get(context, params = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(params).toString();
      window.axios
        .get("/contents/materials?" + query_params)
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setMaterials", results);
          context.commit("setCount", count);
          resolve({ count, next, previous, results });
        })
        .catch(reject);
    });
  },
  getMaterial(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/${id}/material`)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  saveProgress(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/progress/material`, data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
