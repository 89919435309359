export default {
  get(context, params = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(params).toString();
      window.axios
        .get(`/contents/modules?${query_params}`)
        .then(function (response) {
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },
  getModule(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/${id}/module`)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getModuleCourses(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/modules/${id}/course`)
        .then((response) => {
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },
};
