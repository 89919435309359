<template>
  <MDBBtn
    :color="variant"
    @click="$emit('click')"
    :type="type"
    :disabled="disabled"
    :class="classesObj"
  >
    <slot />
  </MDBBtn>
</template>
<script>
import { MDBBtn } from "mdb-vue-ui-kit";
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    classes: {
      type: [String, Object],
      default: "",
    },
  },
  components: {
    MDBBtn,
  },
  computed: {
    classesObj() {
      return {
        ...this.classes,
        "d-flex justify-content-center align-items-center px-4 py-2": true,
      };
    },
  },
};
</script>
