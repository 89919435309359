import { object_to_array } from "@/functions/helpers";
import get from "lodash/get";

export default {
  methods: {
    check_formation_purchase(formation_id) {
      if (!formation_id) return false;

      const purchased_formations_ids = object_to_array(
        get(this.$store.state.user, "user.my_contents_ids.formation", [])
      );

      const formation = purchased_formations_ids.find(
        (id) => id == formation_id
      );

      return formation;
    },
    check_course_purchase(course_id) {
      if (!course_id) return false;

      const purchased_courses_ids = object_to_array(
        get(this.$store.state.user, "user.my_contents_ids.course", [])
      );

      const course = purchased_courses_ids.find((id) => id == course_id);

      return course;
    },
    check_live_purchase(live_id) {
      if (!live_id) return false;

      const purchased_live_ids = object_to_array(
        get(this.$store.state.user, "user.my_contents_ids.live", [])
      );

      const live_is_purchased = purchased_live_ids.find((id) => id == live_id);

      return live_is_purchased;
    },
    check_material_purchase(material_id) {
      if (!material_id) return false;

      const purchased_material_ids = object_to_array(
        get(this.$store.state.user, "user.my_contents_ids.futher_material", [])
      );

      const material_is_purchased = purchased_material_ids.find(
        (id) => id == material_id
      );

      return material_is_purchased;
    },
  },
};
