<template>
  <div v-if="renderControl" class="component-bread-crumbs">
    <div class="d-flex align-items-center">
      <template v-for="(crumb, i) in crumbs" :key="crumb">
        <template v-if="canViewCrumb(crumb)">
          <i v-if="i > 0" class="icon-angle-right me-2"></i>
          <router-link
            v-if="crumb.route.length"
            class="me-2 my-0 py-0"
            :to="{ name: crumb.route, params: getParams(crumb) }"
          >
            {{ crumb.title }}
          </router-link>
          <span v-else class="me-2 my-0 py-0">{{ crumb.title }}</span>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({ renderControl: true }),
  watch: {
    formation() {
      this.refresh();
    },
  },
  computed: {
    ...mapState("formations", ["formation"]),
    crumbs() {
      if (this.$route.meta.breadcrumbs) return this.$route.meta.breadcrumbs;

      return [];
    },
  },
  methods: {
    refresh() {
      this.renderControl = false;

      this.$nextTick(() => {
        this.renderControl = true;
      });
    },
    mapKeyParams(source, key_params) {
      let p = {};
      for (const key in key_params) {
        p[key_params[key]] = source[key_params[key]];
      }

      return p;
    },
    getParams(crumb) {
      let params = {};

      if (crumb.route == "formation" && this.formation) {
        params.id = this.formation.id;
      } else if (crumb.route != "formation" && crumb.key_params) {
        params = this.mapKeyParams(this.$route.params, crumb.key_params);
      }

      if (params.module_id) {
        delete params.id;
        params.id = params.module_id;
      }

      return params;
    },
    canViewCrumb(crumb) {
      let view = true;

      if (crumb.key_params?.length) {
        /*
          If have key_params, check if these params exist
          if not exist, don't show the crumb in template
        */
        const params = this.getParams(crumb);
        view = crumb.key_params.reduce((acc, key) => acc && params[key], true);
      }

      return view;
    },
  },
};
</script>
<style lang="scss" scoped>
.component-bread-crumbs {
  position: absolute;
  top: 1rem;
  left: 2%;

  &.top-fixed-0 {
    @media screen and (max-width: 450px) {
      top: 0.2%;
    }
  }

  a {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.0025em;
    font-weight: 700;
    text-shadow: 0 0 10px #3c3c3c;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  span {
    color: #ffffff;
    text-shadow: 0 0 10px #3c3c3c;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  i {
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }
}
</style>
