<template>
  <div class="components-home-text-item w-100">
    <h4 class="text-start title">
      {{ item.title || item.name }}
      <i v-if="show_lock" class="icon-lock ms-3"></i>
    </h4>
    <div class="text-start d-flex align-items-center mb-2">
      <div class="type-box me-2" :style="color_type">{{ name_type }}</div>
      <small class="date">{{ date_info }}</small>
    </div>
    <div class="description" v-html="item.description"></div>
  </div>
</template>
<script>
import { removeTimeZone } from "@/functions/helpers";
import SalesMixin from "@/functions/mixins/sales";
import moment from "moment";
export default {
  mixins: [SalesMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: String,
  },
  computed: {
    show_lock() {
      if (this.type == "course")
        return (
          !this.check_course_purchase(this.item.id) &&
          !this.check_formation_purchase(this.item.formation.id)
        );

      return this.check_formation_purchase(this.item.id);
    },
    date_info() {
      return moment(removeTimeZone(this.item.created_at)).format(
        "DD[/]MMM[,] HH:mm"
      );
    },
    name_type() {
      const name_map = {
        course: `Curso`,
        formation: `Formação`,
      };
      return name_map[this.type];
    },
    color_type() {
      const color_map = {
        course: `background-color: #EA7582; color: #8D1927;`,
        formation: `background-color: #54A3DB; color: #fff; `,
      };

      return color_map[this.type];
    },
  },
};
</script>
<style lang="scss" scoped>
.components-home-text-item {
  .title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.001em;
    color: #33333a;
  }

  .type-box {
    border-radius: 8px;
    width: fit-content;
    height: 26px;
    font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 5px;
  }

  .date {
    font-weight: 500 !important;
    text-align: left !important;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.4px;
    color: #9a99ae;
  }

  .description {
    text-align: left !important;
    font-weight: 400 !important;
    & > * {
      text-align: left !important;
      font-weight: 400 !important;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.0025em;
      color: #5a5966;
    }
  }
}
</style>
