import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    notifications: [],
    count: 0,
    lives: {},
    copybooks: {},
  }),

  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setLives(state, lives) {
      state.lives = lives;
    },
    setCopybooks(state, copybooks) {
      state.copybooks = copybooks;
    },
  },

  actions,
};
