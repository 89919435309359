export default {
  get(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/faq/${id}?limit=999999`)
        .then((response) => {
          context.commit("setFaq", response.data.results);
          resolve(response);
        })
        .catch(reject);
    });
  },
  getCategories(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/faq/categories`)
        .then((response) => {
          context.commit("setCategoriesFaq", response.data.results);
          resolve(response);
        })
        .catch(reject);
    });
  },
};
