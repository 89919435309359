<template>
  <MDBModal v-model="innerVisible" centered size="sm">
    <div class="text-center pt-4 mb-4">
      <h4>{{ title }}</h4>
      <div v-html="text" class="p-4 text-start"></div>
    </div>
    <div class="d-flex align-items-center justify-content-between pb-4 px-3">
      <button
        type="button"
        class="btn btn-outline-secondary px-4"
        @click="cancel"
      >
        Cancelar
      </button>
      <button type="button" class="btn btn-danger px-4" @click="confirm">
        Confirmar
      </button>
    </div>
  </MDBModal>
</template>

<script>
import { MDBModal } from "mdb-vue-ui-kit";
export default {
  components: { MDBModal },
  name: "ConfirmationModal",
  props: {
    modelValue: Boolean,
    title: String,
    text: String,
  },
  data() {
    return {
      innerVisible: this.modelValue,
    };
  },
  watch: {
    modelValue(v) {
      this.innerVisible = v;
    },
    innerVisible(v) {
      this.$emit("update:modelValue", v);
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.closed();
    },
    confirm() {
      this.$emit("confirm");
      this.closed();
    },
    closed() {
      this.$emit("update:modelValue", false);
    },
  },
};
</script>

<style lang="scss">
.confirmation {
  width: 80% !important;
  max-width: 400px !important;
  background-color: #333;

  .btn-danger {
    background: #8d1927;
  }
}
</style>
