import { setToken } from "@/setup/api";

export default {
  login(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/signin", data)
        .then(function (response) {
          setToken(response.data.token);
          context.commit("setUser", response.data);
          context.commit("setLogged", true);

          resolve(response);
        })
        .catch(reject);
    });
  },

  register(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/create", data)
        .then(function (response) {
          // setToken(response.data.token);
          // context.commit("setLogged", true);
          // context.dispatch("postLogin", null, { root: true });
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  forgot(context, data) {
    return new Promise((resolve, reject) => {
      window.axios.post("/accounts/forgot", data).then(resolve).catch(reject);
    });
  },

  resetPassword(context, { password, token }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/reset-password", { password, token })
        .then(resolve)
        .catch(reject);
    });
  },

  getMessages(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/students/messages")
        .then(function (response) {
          context.commit("setMessages", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  get(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/accounts/me")
        .then((response) => {
          context.commit("setUser", response.data);
          context
            .dispatch("sessions/getMy", response.data.id, { root: true })
            .then();
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  update(context, { id, data, onUploadProgress }) {
    return new Promise((resolve, reject) => {
      let payload = data;

      if (onUploadProgress) {
        let payload = new FormData();
        for (let k in data) {
          payload.append(k, data[k]);
        }
      }

      window.axios
        .put(`/accounts/${id}`, payload, {
          onUploadProgress: function (event) {
            if (onUploadProgress) onUploadProgress(event);
          },
        })
        .then(function () {
          context.commit("updateUser", data);
          resolve();
        })
        .catch(reject);
    });
  },

  updatePassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts/change-password", data)
        .then(resolve)
        .catch(reject);
    });
  },

  search_content(context, params = {}) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(params).toString();
      window.axios
        .get(`/contents/search?${query_params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
