<template>
  <div
    :class="`live-item ${deny_access ? 'lock' : ''} mb-3`"
    :style="backgroundThumb"
  >
    <i v-if="deny_access" class="icon-lock"></i>
    <div class="gradient d-flex flex-column justify-content-start px-3">
      <p class="text-start">{{ date }}</p>
      <p class="description">{{ item.number }} {{ item.name }}</p>
    </div>
  </div>
</template>
<script>
import SalesMixin from "@/functions/mixins/sales";
import moment from "moment";

export default {
  mixins: [SalesMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    deny_access() {
      return (
        !this.check_live_purchase(this.item.id) &&
        !this.check_formation_purchase(this.item?.formation?.id)
      );
    },
    date() {
      return moment(this.item.created_at).format("DD/MM/YYYY");
    },
    backgroundThumb() {
      const url =
        this.item.thumb || require("@/assets/images/live-placeholder.png");

      let gradient =
        "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)";

      let lock_gradient = "";

      if (this.deny_access)
        lock_gradient =
          "linear-gradient(90deg, rgba(0,0,0, 0.8) 100%, rgba(0,0,0, 0.8) 100%),";

      return `background-image: ${lock_gradient} url(${url}), ${gradient};`;
    },
  },
};
</script>
<style lang="scss" scoped>
.live-item {
  background-color: #6c6c6c;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center center;
  position: relative;
  width: 195px;
  height: 275px;
  border-radius: 0px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-style: normal;
  line-height: 14px;
  font-weight: 700;
  color: #fff;

  @media screen and (max-width: 991px) {
    width: 140px;
    height: 245px;
  }

  p {
    color: #fff;
    font-size: 12px;
  }

  &.lock {
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 28px;
      color: #fff;
    }
  }
}

.gradient {
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 35%;
}

.description {
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  text-align: left !important;
}
</style>
