<template>
  <div class="components-auto-complete-select" v-click-outside="onClickOutside">
    <div class="form-group">
      <input
        class="form-control w-100"
        :placeholder="placeholder"
        v-model="innerValue"
        @click="show = true"
      />
      <button class="btn btn-chevron" type="button" @click="show = true">
        <i class="icon-caret-down" />
      </button>
    </div>
    <div class="suggestions-wrapper p-2" :class="{ show }">
      <button
        v-for="option in filtered_options"
        :key="option.id"
        class="btn btn-dropdown-item"
        type="button"
        @click="onSelect(option)"
      >
        {{ option.name }}
      </button>
    </div>
  </div>
</template>
<script>
import { normalizeText } from "@/functions/helpers";
import vClickOutside from "click-outside-vue3";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: "",
    },
    id: {
      type: String,
      default: "material-select",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    emitAsObject: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "icon-gym-weight",
    },
  },
  data() {
    return {
      innerValue: this.modelValue,
      innerObjectValue: this.modelValue,
      innerIdValue: this.modelValue,
      show: false,
    };
  },
  watch: {
    innerValue(v) {
      this.show = v.length;
    },
    options() {
      this.innerValue = "";
    },
  },
  computed: {
    filtered_options() {
      if (this.innerValue.length) {
        return this.options.filter(
          (o) =>
            normalizeText(JSON.stringify(o)).indexOf(
              normalizeText(this.innerValue)
            ) > -1
        );
      }

      return this.options;
    },
  },
  methods: {
    clear() {
      this.$emit("update:modelValue", "");
    },
    onSelect(option) {
      this.innerIdValue = option.id;
      this.innerObjectValue = option;
      this.innerValue = option.name || option.title;
      if (this.emitAsObject) {
        this.$emit("change-object", this.innerObjectValue);
      }
      this.$emit("update:modelValue", this.innerIdValue);
      this.show = false;
    },
    onClickOutside() {
      if (this.show) this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.components-auto-complete-select {
  position: relative;
  width: fit-content;
  height: fit-content;

  .suggestions-wrapper {
    background: #f2fff5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 9999;

    button {
      min-width: 90%;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    position: absolute;
    top: 90%;
    left: 0;
    display: none;
    height: 0;

    &.show {
      display: block;
      height: fit-content;
    }
  }

  .form-group {
    position: relative;
    .btn-chevron {
      position: absolute;
      margin: 0;
      padding: 0;
      right: 5%;
      top: 50%;
      transform: translate(-5%, -50%);

      i {
        color: #000;
        font-size: 10px;
      }
    }

    .form-control {
      background: #f0eeff;
      border-radius: 12px;
      padding-bottom: 10px;
      padding-top: 10px;
      font-size: 14px;
      position: relative;
      margin: 0;

      letter-spacing: 0.0025em;
      font-style: normal;
      font-weight: 400;
      color: #5a5966;
      font-size: 14px;
      border: none;
      outline: none;
      box-shadow: none;
      background: #f0eeff;

      justify-content: flex-start;

      i {
        color: #5a5966;
        font-size: 14px;
      }

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
        background: #f0eeff;
      }

      &:focus-visible {
        outline-offset: none;
        outline: none;
      }

      &::after {
        content: " ";
        display: inline-block;
        position: absolute;
        z-index: 3;
        top: 46%;
        right: 3%;
        width: 0;
        height: 0;
        border-radius: 8px 8px 8px 0px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
      }
    }
  }

  .btn-dropdown-item {
    font-weight: 400 !important;
    font-size: 14px;
    letter-spacing: 0.0025em;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #33333a;
    text-align: left;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0;

    &:hover {
      background-color: #33333a20;
    }
  }

  .placeholder-color {
    color: #5a5966;
  }
}
</style>
